import React, { useState, useEffect } from "react";
import {
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  MainConatiner,
} from "./styles";
import { FaExpandAlt, FaMinus, FaSquare } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaRegSquareFull } from "react-icons/fa6";
import useGetData from "../../../hooks/useGetData";
import { CrossIcon } from "../../../common/customPill/styles";
import { handleRemoveInternalChatSession } from "../../../redux/app/appSlice";
import SpaceSmallerView from "../spaceSmallerView/SpaceSmallerView";
import SessionSmallerView from "../sessionSmallerView/SessionSmallerView";

const SessionCard = ({ session }) => {
  const { auth, app } = useSelector((state) => state);
  const [isVisible, setIsVisible] = useState(true);

  const dispatch = useDispatch();

  const TruncateText = ({ text }) => {
    const maxLength = 25;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const removeSessionFromList = () => {
    dispatch(handleRemoveInternalChatSession(session));
  };

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return firstInitial + lastInitial;
  };

  return (
    <MainConatiner style={{ height: isVisible ? "500px" : "50px" }}>
      <Header>
        <div className="make-row">
          <div className="icon">
            {session?.avatar ? (
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={session.avatar}
                alt="User Avatar"
              />
            ) : (
              <div
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "20px",
                  overflow: "hidden",
                  background: "#4cbbcb",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  color: "#fff",
                  fontWeight: "600",
                  border: "1px solid #e3eced",
                }}
              >
                {getInitials(session?.first_name, session?.last_name)}
              </div>
            )}
          </div>
          <HeaderTitle>
            <TruncateText
              text={session?.first_name + " " + session?.last_name || ""}
            />
          </HeaderTitle>
        </div>
        <div className="make-row">
          {isVisible ? (
            <FaMinus
              style={{ cursor: "pointer", marginRight: "5px" }}
              size={16}
              onClick={() => setIsVisible(false)}
            />
          ) : (
            <FaRegSquareFull
              style={{ cursor: "pointer", marginRight: "5px" }}
              size={16}
              onClick={() => setIsVisible(true)}
            />
          )}
          <FaExpandAlt
            style={{ cursor: "pointer" }}
            size={16}
            onClick={() => {
              removeSessionFromList();
            }}
          />
          <CrossIcon
            size={17}
            style={{ cursor: "pointer", marginLeft: "1px" }}
            onClick={() => removeSessionFromList()}
          />
        </div>
      </Header>
      {isVisible && (
        <>
          <SessionSmallerView session={session} />
        </>
      )}
    </MainConatiner>
  );
};

export default SessionCard;
