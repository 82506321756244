import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  background: var(--White-80, #FFFFFFCC);
  
  border-top: none;
  border-left: none;
  margin-top: 40px;
  border-bottom: 1px solid var(--Gray-5, #5563730D);
padding: 20px 40px 20px 40px;
gap: 0px;
border: 0px 0px 1px 0px;
justify: space-between;

  .title {
    color: var(--Dark-Gray-100, #2B3746);
    font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.035em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
   }

   .save-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
   }
`;

export { Container };
