import React, { useEffect, useState } from 'react'
import { Form, Input, Select, DatePicker, TimePicker, TextArea } from 'antd'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { FormConatiner } from './styles'
import dayjs from 'dayjs'
import useGetData from '../../../hooks/useGetData'
import { useSelector } from 'react-redux'
import { RiArrowDropDownFill } from 'react-icons/ri'
import debounce from 'lodash.debounce'
import { PiDotOutlineFill } from 'react-icons/pi'
import _ from 'lodash'
import moment from 'moment'
import { InstanceWithAuth } from '../../../App'
function EditMeetingForm({
  form,
  meetingDetails,
  setIsMeetingCancel,
  isMeetingCancel,
  holidays,
  handleFormItemChange,
  setInitialValues,
}) {
  const [showCursor, setShowCursor] = useState(false)
  const [selectedLoaction, setSelectedLoaction] = useState()
  const [selectedPaymentOption, setSelectedPaymentOption] = useState()
  const [updatedEndTime, setUpdatedEndTime] = useState('')
  const [updatedStartTime, setUpdatedStartTime] = useState('')
  const [enablePayement, setEnablePayement] = useState()
  const [creditsNeeded, setCreditsNeeded] = useState(0)
  const [formDisable, setFormDisbale] = useState(true)
  const [creditDetails, setCreditDetails] = useState()
  const { auth, app } = useSelector((state) => state)
  // const debouncedFetchCreditDetails = debounce(() => {
  //   refetchCreditData()
  // }, 500)
  const {
    data: locationData,
    loading: locationDataLoading,
    error: locationDataError,
  } = useGetData('locations/dropdown/get')

  const handleFinish = (values) => {
    console.log('Form Submitted:', values)
  }

  const {
    data: meetinDetailsData,
    loading: meetinDetailsDataLoading,
    error: meetinDetailsDataError,
  } = useGetData(`meeting-events/${meetingDetails?.meetingId}`)
  const defaulStartTime = moment(meetinDetailsData?.data?.booking_start).format(
    'YYYY-MM-DD HH:mm:ss'
  )
  const defaultEndTime = moment(meetinDetailsData?.data?.booking_end).format(
    'YYYY-MM-DD HH:mm:ss'
  )
  //   const {
  //     data: creditDetails,
  //     loading: creditLoading,
  //     refetchData: refetchCreditData,
  //   } = useGetData(`meeting-events/insufficient-credit/${
  //     meetinDetailsData?.data?.id
  //   }?booking_start=${
  //     updatedStartTime ? updatedStartTime : defaulStartTime
  //   }&booking_end=${
  //     updatedEndTime ? updatedEndTime : defaultEndTime
  //   }&company_id=${meetinDetailsData?.data?.company_id}
  // `)

  const fetchData = async () => {
    try {
      const response = await InstanceWithAuth.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/meeting-events/insufficient-credit/${
          meetinDetailsData?.data?.id
        }?booking_start=${
          updatedStartTime ? updatedStartTime : defaulStartTime
        }&booking_end=${
          updatedEndTime ? updatedEndTime : defaultEndTime
        }&company_id=${meetinDetailsData?.data?.company_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )
      setCreditDetails(response.data)
    } catch (error) {
      // setCreditData(error)
    }
  }
  console.log(creditDetails, 'creditData')

  useEffect(() => {
    fetchData()
  }, [updatedEndTime, updatedEndTime])
  const {
    data: meetingRoomsData,
    refetchData: refetchMeetingRoomsData,
    loading: loadingMeetingRoomsData,
  } = useGetData(`meeting-rooms/loaction/${selectedLoaction}`)

  useEffect(() => {
    refetchMeetingRoomsData()
  }, [selectedLoaction, meetinDetailsData?.data?.meeting_room_id])
  const onDropDownVisible = () => {
    setShowCursor(!showCursor)
  }
  useEffect(() => {
    setFormDisbale(creditDetails?.additional_credits_needed > 0 ? false : true)
  }, [creditDetails?.additional_credits_needed])

  const { Option } = Select
  const { TextArea } = Input

  const [options, setOptions] = useState([])
  const [userloading, setUserLoading] = useState(false)
  const isMeetingExpired = new Date(meetinDetailsData?.data?.booking_start) < new Date()
  useEffect(() => {
    form.setFieldsValue({
      user: meetinDetailsData?.data?.company_id,
      Location: meetinDetailsData?.data?.location_id,
      meetingRoom: meetinDetailsData?.data?.meeting_room_id,

      bookinDate: dayjs(meetinDetailsData?.data?.booking_date),

      startTime: dayjs(meetinDetailsData?.data?.booking_start),

      endTime: dayjs(meetinDetailsData?.data?.booking_end),
      title: meetinDetailsData?.data?.title,
      notes: meetinDetailsData?.data?.description,
      paymentOption: meetinDetailsData?.data?.payment_option,
      quantity: meetinDetailsData?.data?.credit_quantity,
    })

    setSelectedLoaction(meetinDetailsData?.data?.location_id)
    setSelectedPaymentOption(meetinDetailsData?.data?.payment_option)
    const initialData = {
      user: meetinDetailsData?.data?.company_id,
      Location: meetinDetailsData?.data?.location_id,
      meetingRoom: meetinDetailsData?.data?.meeting_room_id,

      bookinDate: dayjs(meetinDetailsData?.data?.booking_date),

      startTime: dayjs(meetinDetailsData?.data?.booking_start),

      endTime: dayjs(meetinDetailsData?.data?.booking_end),
      title: meetinDetailsData?.data?.title,
      notes: meetinDetailsData?.data?.description,
      paymentOption: meetinDetailsData?.data?.payment_option,
      quantity: meetinDetailsData?.data?.credit_quantity,
    }

    setInitialValues(initialData)
  }, [meetinDetailsData?.data])

  useEffect(() => {
    fetchUsers(meetinDetailsData?.data?.user?.id)
  }, [meetinDetailsData?.data, form, selectedLoaction])

  const fetchUsers = async (searchValue) => {
    setUserLoading(true)

    const formData = new FormData()
    formData.append('query', searchValue || '')
    formData.append('location_id', selectedLoaction || '')

    try {
      const response = await InstanceWithAuth.post(
        `meeting-events/users`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )

      if (response.status === 200 || response.status === 201) {
        const formattedOptions = response.data?.data.map((user) => ({
          value: user.id,
          label: (
            <div
              className="select-dropdown"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                className="MailBox-handleUserDetails"
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  // width: '100%',
                  gap: '3px',
                }}
              >
                <span
                  style={{
                    color: 'var(--Dark-Gray-100, #2B3746)',
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '120%',
                    width: '110px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {`${user?.contact_first_name} ${user?.contact_last_name}`}
                </span>
                <span
                  style={{
                    color: 'var(--Gray-60, rgba(85, 99, 115, 0.60))',
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '120%',
                    width: '90px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {user?.name}
                </span>
              </div>
              <span
                style={{
                  display: 'flex',
                  color: 'var(--Gray-60, rgba(85, 99, 115, 0.60))',
                  fontFamily: 'Outfit',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '120%',
                  width: '69px',
                }}
              >
                {`${user?.meeting_room_credits_left} Credits`}
              </span>
            </div>
          ),
        }))
        setOptions(formattedOptions) // Update options state
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setUserLoading(false)
    }
  }

  const handleSearch = debounce((value) => {
    if (value) {
      fetchUsers(value)
    } else {
      setOptions([]) // Clear options when the search input is empty
    }
  }, 300)
  const paymentOptions = [
    { name: 'Use Credits', id: 1 },
    { name: 'Use Credits & Payment', id: 2 },
    { name: 'Continue without charge', id: 3 },
  ]

  const handleAddPaymentOption = (value) => {
    setSelectedPaymentOption(value)
  }
  const handleCheckboxChange = (event) => {
    setIsMeetingCancel(event.target.checked ? 1 : 0)
  }
  const disabledDate = (current) => {
    if (!current) return false

    const isPastDate = current < moment().startOf('day')
    const dayOfWeek = current.day()

    if (isPastDate || dayOfWeek === 0 || dayOfWeek === 6) {
      return true
    }

    const isHoliday = holidays?.includes(current.format('YYYY-MM-DD'))
    return isHoliday
  }

  return (
    <FormConatiner>
      <Form
        layout="vertical"
        requiredMark={false}
        className="form"
        // onFinish={handleSubmitAddMail}
        // ref={addMailRef}
        disabled={new Date(meetinDetailsData?.data?.booking_start) < new Date()}
        form={form}
        onValuesChange={handleFormItemChange}
      >
        <div className="form-title">Provide booking details</div>
        <Form.Item label="Title" name="title" className="input-label">
          <Input className="username-input" placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Meeting Description"
          name="notes"
          className="input-label"
          
        >
          <TextArea
            rows={2}
            placeholder="Description here"
            className="text-area"
            maxLength={200}
          />
        </Form.Item>
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className="form"
        onValuesChange={handleFormItemChange}
        // onFinish={handleSubmitAddMail}
        // ref={addMailRef}
        style={{ marginTop: "29px"}}
        disabled={new Date(meetinDetailsData?.data?.booking_start) < new Date()}
        form={form}
      >
        
        <Form.Item
          label="Date"
          name="bookinDate"
          className="input-label"
          rules={[{ required: true, message: 'Select a date!' }]}
        >
          <DatePicker
            placeholder="December 5, 2021"
            format="YYYY-MM-DD"
            className="username-input"
            disabledDate={disabledDate}
          />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            gap: '10px',
            width: '100%',
          }}
        >
          <Form.Item
            label="From"
            name="startTime"
            className="input-label"
            rules={[{ required: true, message: 'Select Start Time!' }]}
          >
            <TimePicker
              className="username-input two-inputs-quantity"
              format="h:mm A"
              suffixIcon={<RiArrowDropDownFill />}
              changeOnScroll
              needConfirm={false}
              onChange={(time, timeString) => {
                if (time && meetinDetailsData?.data?.booking_date) {
                  const bookingDate = meetinDetailsData.data.booking_date

                  if (!moment(time).isValid()) {
                    console.error('Invalid Time Selected:', time)
                    setUpdatedEndTime(null)
                    return
                  }

                  const formattedTime = time.format('HH:mm:ss') // Directly format time
                  const dateTime = moment(
                    `${bookingDate} ${formattedTime}`,
                    'YYYY-MM-DD HH:mm:ss'
                  )

                  console.log('Selected Time:', timeString) // Debugging log
                  console.log('Formatted Time:', formattedTime) // Debugging log
                  console.log(
                    'Updated DateTime:',
                    dateTime.format('YYYY-MM-DD HH:mm:ss')
                  ) // Debugging log

                  setUpdatedStartTime(dateTime.format('YYYY-MM-DD HH:mm:ss'))
                  // debouncedFetchCreditDetails()
                } else {
                  setUpdatedStartTime(null)
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Till"
            name="endTime"
            className="input-label"
            rules={[{ required: true, message: 'Select End Time!' }]}
          >
            <TimePicker
              className="username-input two-inputs-quantity"
              format="h:mm A"
              needConfirm={false}
              suffixIcon={<RiArrowDropDownFill />}
              onChange={(time, timeString) => {
                if (time && meetinDetailsData?.data?.booking_date) {
                  const bookingDate = meetinDetailsData.data.booking_date

                  if (!moment(time).isValid()) {
                    console.error('Invalid Time Selected:', time)
                    setUpdatedEndTime(null)
                    return
                  }

                  const formattedTime = time.format('HH:mm:ss') // Directly format time
                  const dateTime = moment(
                    `${bookingDate} ${formattedTime}`,
                    'YYYY-MM-DD HH:mm:ss'
                  )

                  console.log('Selected Time:', timeString) // Debugging log
                  console.log('Formatted Time:', formattedTime) // Debugging log
                  console.log(
                    'Updated DateTime:',
                    dateTime.format('YYYY-MM-DD HH:mm:ss')
                  ) // Debugging log

                  setUpdatedEndTime(dateTime.format('YYYY-MM-DD HH:mm:ss'))
                  // debouncedFetchCreditDetails()
                } else {
                  setUpdatedEndTime(null)
                }
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Location"
          name="Location"
          className="input-label"
          rules={[{ required: true, message: 'Select Location!' }]}
        >
          <Select
            disabled
            className={showCursor ? 'select-input' : 'select-input hide-cursor'}
            suffixIcon={
              <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
            }
            onChange={(value) => setSelectedLoaction(value)}
            placeholder="Location"
            options={locationData?.data?.map((item) => ({
              value: item?.key,
              label: `${item?.label}, ${item?.state}`,
            }))}
            onDropdownVisibleChange={() => onDropDownVisible()}
          />
        </Form.Item>
        <Form.Item
          label="Meeting Room"
          name="meetingRoom"
          className="input-label"
          rules={[{ required: true, message: 'Select MeetinRoom!' }]}
        >
          <Select
            disabled
            className={showCursor ? 'select-input' : 'select-input hide-cursor'}
            suffixIcon={
              <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
            }
            placeholder="Meeting Room"
            options={meetingRoomsData?.data?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))}
            onDropdownVisibleChange={() => onDropDownVisible()}
          />
        </Form.Item>
        <Form.Item
          label="User"
          name="user"
          className="input-label"
          rules={[{ required: true, message: 'Select User!' }]}
        >
          <Select
            disabled
            className={showCursor ? 'select-input' : 'select-input hide-cursor'}
            suffixIcon={
              <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)" />
            }
            showSearch
            placeholder="Select a User"
            onSearch={handleSearch}
            onDropdownVisibleChange={onDropDownVisible}
            options={options}
            loading={userloading}
            // notFoundContent={userloading ? 'Loading...' : 'No users found'}
            filterOption={false}
          />
        </Form.Item>
        {}
        <Form.Item
          label="Payment Option"
          name="paymentOption"
          className="input-label"
          rules={[{ required: true, message: 'Select Payment Option!' }]}
        >
          <Select
            disabled={formDisable}
            className={showCursor ? 'select-input' : 'select-input hide-cursor'}
            suffixIcon={
              <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
            }
            options={paymentOptions?.map((item) => ({
              value: item?.name,
              label: item?.name,
            }))}
            onChange={(value) => handleAddPaymentOption(value)}
            placeholder="Select a Payment Option"
            onDropdownVisibleChange={() => onDropDownVisible()}
          />
        </Form.Item>
        <div className="error-msg">
          {creditDetails?.additional_credits_needed > 1
            ? `${creditDetails?.additional_credits_needed} Additional Credits Needed`
            : creditDetails?.additional_credits_needed > 0
            ? `${creditDetails?.additional_credits_needed} Additional Credit Needed`
            : ''}
        </div>
      </Form>
      
    </FormConatiner>
  )
}

export default EditMeetingForm
