import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import "./styles";
import TicketFilters from "./ticketFilters/TicketFilters";
import AddFilters from "./addFilters/AddFilters";
import {
  FaTicketAlt,
  FaUser,
  FaCalendarAlt,
  FaStream,
  FaTags,
  FaTasks,
  FaBuilding,
  FaPlus,
} from "react-icons/fa";
import useGetData from "../../../hooks/useGetData";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import TruncketTextAndHover from "../../../common/TruncketTextAndHover";

const AdvancedTicketFilters = ({
  setAdvancedFilters,
  setLoadingFilteredTicktes,
  openContactDrawer,
  setAppliedAdvancedFillters,
  groups,
  setGroups,
  setOpenContactDrawer,
  refetchTikcedBoardData,
  selectedPipeline,
}) => {
  const {
    data: contacts,
    loading: loadingContacts,
    refetchData: refetchContacts,
  } = useGetData("contacts");

  const {
    data: companies,
    loading: loadingCompanies,
    refetchData: refetchCompanies,
  } = useGetData("companies");

  const { data: pipelines, error: pipelinesError } = useGetData(
    `support/tickets/pipelines?status=1`
  );

  const { data: employees } = useGetData("users-home");
  const { auth, app } = useSelector((state) => state);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [priority, setPriority] = useState(null);
  const [category, setCategory] = useState([]);
  const [channel, setChannel] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);
  const [status, setStatus] = useState(null);
  const [pipeline, setPipeline] = useState([]);
  const [stage, setStage] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);

  console.log("selectedPipeline", selectedPipeline);

  const addGroup = () => {
    setGroups((prevGroups) => [...prevGroups, { operator: "OR", filters: [] }]);
  };

  useEffect(() => setSelectedGroupIndex(0), []);

  const buildRequestBody = () => {
    const fieldIdentifiers = ["name", "description", "status"];

    const arrayFieldIdentifiers = [
      "support_pipeline_stage_id",
      "support_pipeline_id",
    ];

    const separateArray = ["priority", "support_category", "channel"];

    return {
      order_by: "created_at",
      order: "DESC",
      support_pipeline_id: app?.userSelcetedPipeLine
        ? app?.userSelcetedPipeLine?.id
        : "",
      groups: groups?.map((group) => ({
        operator: group?.operator,
        filters: [
          ...(group?.filters
            ?.map((filter) => ({
              field: filter?.field?.identifier,
              operator: filter?.operator?.replace(/_/g, "_"),
              value: arrayFieldIdentifiers?.includes(filter?.field?.identifier)
                ? filter?.value?.length > 0
                  ? filter?.value?.map((item) => item?.id)
                  : []
                : fieldIdentifiers?.includes(filter?.field?.identifier)
                ? filter?.value
                : separateArray?.includes(filter?.field?.identifier)
                ? filter?.value?.length > 0
                  ? filter?.value
                  : []
                : filter?.value?.id,
            }))
            .filter(Boolean) || []),
        ],
      })),
    };
  };

  const fetchTickets = async () => {
    setLoadingFilteredTicktes(true);
    const requestBody = buildRequestBody();
    setAppliedAdvancedFillters(requestBody);

    try {
      const response = await InstanceWithAuth.post(
        `support/tickets/advance-filters`,
        JSON.stringify(requestBody),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201 || response?.status === 200) {
        setAdvancedFilters(response?.data);
        setLoadingFilteredTicktes(false);
      }
    } catch (error) {
      console.error("Failed to add reaction:", error);
      setLoadingFilteredTicktes(true);
    }
  };

  useEffect(() => {
    if (groups.some((group) => group?.filters && group?.filters?.length > 0)) {
      fetchTickets();
    }
  }, [groups, app?.userSelcetedPipeLine]);

  // useEffect(() => {
  //   const requestBody = buildRequestBody();
  //   localStorage.setItem("ticketAdvancedFilters", JSON.stringify(requestBody));
  //   localStorage.setItem("openFiltersDrawer", openContactDrawer);
  // }, [groups, app?.userSelcetedPipeLine]);

  // // Load saved filters from localStorage on component mount
  // useEffect(() => {
  //   const savedFilters = localStorage.getItem("ticketAdvancedFilters");
  //   if (savedFilters) {
  //     const parsedFilters = JSON.parse(savedFilters);
  //     setGroups(parsedFilters.groups || [{ operator: "AND", filters: [] }]);
  //   }
  // }, []);

  const sharedProps = {
    selectedContact,
    setSelectedContact,
    selectedCompany,
    setSelectedCompany,
    selectedOwner,
    setSelectedOwner,
    priority,
    setPriority,
    category,
    setCategory,
    channel,
    setChannel,
    createdAt,
    setCreatedAt,
    status,
    setStatus,
    pipeline,
    setPipeline,
    stage,
    setStage,
  };

  return (
    <Container>
      <div className="left">
        <TicketFilters
          groups={groups}
          setGroups={setGroups}
          setSelectedProperty={setSelectedProperty}
          addGroup={addGroup}
          selectedGroupIndex={selectedGroupIndex}
          setSelectedGroupIndex={setSelectedGroupIndex}
          setSelectedFilter={setSelectedFilter}
          setAdvancedFilters={setAdvancedFilters}
          refetchTikcedBoardData={refetchTikcedBoardData}
        />
      </div>
      <div className="right">
        <AddFilters
          {...sharedProps}
          contacts={contacts}
          companies={companies}
          pipelines={pipelines}
          employees={employees}
          ticketProperties={ticketProperties}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          filters={filters}
          setFilters={setFilters}
          groups={groups}
          setGroups={setGroups}
          selectedGroupIndex={selectedGroupIndex}
          selectedFilter={selectedFilter}
          setOpenContactDrawer={setOpenContactDrawer}
          refetchTikcedBoardData={refetchTikcedBoardData}
          setAdvancedFilters={setAdvancedFilters}
        />
      </div>
    </Container>
  );
};

export default AdvancedTicketFilters;

const ticketProperties = [
  {
    identifier: "name",
    name: "Ticket Name",
    icon: <FaTicketAlt />,
    options: [
      "is known",
      "is unknown",
      "contains exactly",
      "does not contain exactly",
    ],
  },
  {
    identifier: "description",
    name: "Ticket Description",
    icon: <FaStream />,
    options: [
      "is known",
      "is unknown",
      "contains exactly",
      "does not contain exactly",
    ],
  },
  {
    identifier: "contact_id",
    name: "Associated Contact",
    icon: <FaUser />,
    options: ["is equal to", "is not equal to"],
  },
  {
    identifier: "company_id",
    name: "Company",
    icon: <FaBuilding />,
    options: ["is equal to", "is not equal to"],
  },
  {
    identifier: "user_id",
    name: "Ticket Owner",
    icon: <FaUser />,
    options: ["is equal to", "is not equal to"],
  },
  {
    identifier: "priority",
    name: "Priority",
    icon: <FaTags />,
    options: ["is any of", "is none of", "is known", "is unknown"],
  },
  {
    identifier: "support_category",
    name: "Support Category",
    icon: <FaTasks />,
    options: ["is any of", "is none of", "is known", "is unknown"],
  },
  {
    identifier: "channel",
    name: "Channel",
    icon: <FaStream />,
    options: ["is any of", "is none of", "is known", "is unknown"],
  },
  // {
  // identifier: "created_at",
  //   name: "Create Date",
  //   icon: <FaCalendarAlt />,
  //   options: ["is", "is equal to", "before", "after", "in between"],
  // },
  {
    identifier: "status",
    name: "Status",
    icon: <FaTasks />,
    options: ["is equal to", "is not equal to"],
  },
  {
    identifier: "support_pipeline_id",
    name: "Pipeline",
    icon: <FaStream />,
    options: ["is any of", "is none of", "is known", "is unknown"],
  },
  {
    identifier: "support_pipeline_stage_id",
    name: "Stage",
    icon: <FaTasks />,
    options: ["is any of", "is none of", "is known", "is unknown"],
  },
];
