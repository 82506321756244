import styled from 'styled-components'

const ProductCardWrapper = styled.div`
  width: 100%;
  border: 1px solid #d0dee1;
  border-radius: 8px;
  background-color: #fafbfc;
  .detail-warpper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border-bottom: 1px solid #d0dee1;
    background: #fff;
        height: 60px;

    .title-div {
      display: flex;
      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      cursor: pointer;
      .view-btn {
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
      .title-progress-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        max-width: 702px;
        width: 100%;

        .forward {
          display: flex;
          width: 26px;
          height: 26px;
          padding: 0px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;

          svg {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            fill: #2b3746;
          }
        }

        .progress-wrapper {
          display: flex;
          gap: 16px;
          .storage {
            width: 101px;
            flex-shrink: 0;
            color: var(--Dark-Gray-100, #2b3746);
            text-align: right;
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */ /* 16.8px */
          }
          .ant-progress-bg {
            height: 12px !important;
            max-width: 183px !important;
            width: 100%;
            /* border: 1px solid #70c9d5;
        clip-path: inset(0px auto 0px 0px) !important; */
            /* border-radius: 4px !important; */
            background: linear-gradient(
                0deg,
                rgba(112, 201, 213, 0.52) 0%,
                rgba(112, 201, 213, 0.52) 100%
              ),
              #fff;
            /* width: 94px; */
            height: 12px !important;
          }
          .used {
            max-width: 130px;
            width: 100%;
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
        .title-wrapper {
          display: flex;
          max-width: 250px;
          width: 100%;
          align-items: center;
          gap: 16px;
          justify-content: space-between;
          .make-column {
          display: flex;
          flex-direction: column;
          padding: 7px 0px;
          .number {
          
          
          color: var(--Blue-100, #4cbbcb);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0;}
          }
          .title {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            // line-height: 160%;
          }
          .active-btn {
            /* min-width: 92px; */
            width: auto;
            height: 26px;
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
            background: rgba(76, 203, 96, 0.2);
            outline: none;
            .btn-icon {
              svg {
                width: 8px;
                height: 8px;
              }
            }

            .btn-text {
              color: var(--Dark-Gray-100, #2b3746);
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              width: max-content;
              /* width: auto; */
            }
          }
          .inactive-btn {
            /* min-width: 92px; */
            width: auto;
            height: 26px;
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: 1px solid #ff7b7b;
            background: #ffbaba;
            outline: none;
            .btn-icon {
              svg {
                width: 8px;
                height: 8px;
                fill: #ff7b7b;
                fill-opacity: unset;
                circle {
                  fill: #ff7b7b;
                  fill-opacity: unset;
                  stroke: #ff7b7b;
                }
              }
            }

            .btn-text {
              color: var(--Dark-Gray-100, #2b3746);
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              width: max-content;
              /* width: auto; */
            }
          }
        }
      }
    }
  }
  .item-div {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    /* background-color: #fafbfc; */
    /* border: 1px solid #d0dee1; */
    border-top: none;
    :hover {
      background-color: red;
      border-radius: 8px;
      background: rgba(233, 241, 242, 0.6);
    }
    :hover.item-wrapper .item .edit-view {
      visibility: visible;
    }

    .item-wrapper {
      display: flex;
      padding: 8px 8px 8px 16px;
      align-items: center;
      align-self: stretch;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .add-title {
          min-width: 189px;
          width: 100%;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .add-value {
          width: 100%;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .add-amount {
          min-width: 52px;
          width: 100%;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          text-align: right;
        }
        .edit-view {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 11px;
          /* max-width: 245px; */
          visibility: hidden;

          width: 100%;

          .edited-date {
            max-width: 183px;
            width: 100%;
            color: var(--Gray-60, rgba(85, 99, 115, 0.6));
            text-align: right;
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            /* 133.333% */
          }
          .view-btn {
            max-width: 52px;
            width: 100%;
            display: flex;
            padding: 6px 12px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 4px;
            border: 1px solid #d0dee1;
            background: #fff;
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }
        }
      }
    }
  }
`

export { ProductCardWrapper }
