import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaCopy } from "react-icons/fa";

const Wrapper = styled.div`
  position: relative;
`;

const TooltipContainer = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: ${(props) => props.marginTop || "-12px"};
  ${"" /* left: 50%; */}
  transform: translateX(-30%);
  max-width: 300px;
  z-index: 1000;
  white-space: nowrap;
  border: 1px solid #e3eced;

  &::after {
    content: "";
    position: absolute;
    top: 100%; /* Tooltip arrow below the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

const TooltipItem = styled.div`
  margin-bottom: 8px;
  display: flex;
  // align-items: center;

  .label {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 55px;
  }
  .text {
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Gray-100, #556373);
    margin-left: 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

const CopyIcon = styled(FaCopy)`
  margin-left: 8px;
  cursor: pointer;
  color: #4cbbcb;

  &:hover {
    color: #4cbbcb;
  }
`;

const CopiedAlert = styled.div`
  color: #4cbbcb;
  margin-top: 10px;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

const MessageDetailsTooltip = ({
  from,
  to,
  date,
  subject,
  marginTop,
  closeTooltip,
  recipients,
  mailDetails,
}) => {
  const [copiedText, setCopiedText] = useState("");
  const tooltipRef = useRef(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(""), 2000); // Clear the copied text after 2 seconds
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        closeTooltip();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeTooltip]);

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <Wrapper>
      <TooltipContainer ref={tooltipRef} marginTop={marginTop}>
        <TooltipItem>
          <strong className="label">From: </strong>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                className="text"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                  maxWidth: "150px",
                }}
              >
                {mailDetails?.content?.from_email}
              </span>
              <CopyIcon onClick={() => copyToClipboard(to)} />
            </div>
          </div>
        </TooltipItem>

        <TooltipItem>
          <strong className="label">To: </strong>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {recipients?.map((recipients) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className="text"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    maxWidth: "150px",
                  }}
                >
                  {recipients}
                </span>
                <CopyIcon onClick={() => copyToClipboard(recipients)} />
              </div>
            ))}
          </div>
        </TooltipItem>
        <TooltipItem>
          <strong className="label">Date: </strong>
          <span className="text">{date}</span>
        </TooltipItem>
        <TooltipItem>
          <strong className="label">Subject: </strong>
          <span className="text">
            <TruncateText text={subject}></TruncateText>
          </span>
        </TooltipItem>
        {copiedText && <CopiedAlert>Copied to clipboard!</CopiedAlert>}
      </TooltipContainer>
    </Wrapper>
  );
};

export default MessageDetailsTooltip;
