import React from "react";
import { Container } from "./styles";
import { Form, Input, Switch } from "antd";
import GetLogo from "../../getlogo/getlogo";

const MeetingRoomSettings = ({}) => {
  const [form] = Form.useForm();

  return (
    <Container>
      <span className="title">Meeting Room Access</span>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Credit Hours</span>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Default per hour of Meeting Room"
                name="easy_post_key"
                className="form-items"
              >
                <Input
                  className="input"
                  placeholder="45"
                  suffix="crdit hours"
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Default per hour of Office"
                name="easy_post_key"
                className="form-items"
              >
                <Input
                  className="input"
                  placeholder="45"
                  suffix="crdit hours"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MeetingRoomSettings;
