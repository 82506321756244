import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { BiSolidError } from "react-icons/bi";

const TicketFilters = ({
  groups,
  setGroups,
  setSelectedProperty,
  addGroup,
  selectedGroupIndex,
  setSelectedGroupIndex,
  setSelectedFilter,
  setAdvancedFilters,
  refetchTikcedBoardData,
}) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log("groups", groups);
    validateFilters();
  }, [groups]);

  const getErrorCount = () => {
    let count = 0;

    Object.values(errors).forEach((groupErrors) => {
      count += Object.keys(groupErrors).length;
    });

    return count;
  };

  const validateFilters = () => {
    const newErrors = {};

    groups.forEach((group, groupIndex) => {
      group.filters.forEach((filter, filterIndex) => {
        const { operator, value } = filter;

        // Check if operator is not "is_known" or "is_unknown" and value is empty
        if (
          operator !== "is_known" &&
          operator !== "is_unknown" &&
          (value === "" || value === null || value === undefined)
        ) {
          if (!newErrors[groupIndex]) {
            newErrors[groupIndex] = {};
          }
          newErrors[groupIndex][filterIndex] = "A value is required.";
        }
      });
    });

    setErrors(newErrors);
  };

  const handleDeleteFilter = (groupIndex, filterIndex) => {
    setSelectedProperty(null);
    const updatedGroups = groups.map((group, gIdx) =>
      gIdx === groupIndex
        ? {
            ...group,
            filters: group.filters.filter((_, fIdx) => fIdx !== filterIndex),
          }
        : group
    );

    // Check if the last filter of the last group is deleted
    const isLastFilterDeleted =
      updatedGroups.length === 1 && updatedGroups[0].filters.length === 0;

    if (isLastFilterDeleted) {
      refetchTikcedBoardData();
      setAdvancedFilters(null);
    }

    setGroups(updatedGroups);
  };

  const handleDeleteGroup = (groupIndex) => {
    setSelectedProperty(null);

    const updatedGroups = groups.filter((_, idx) => idx !== groupIndex);

    // If all groups are deleted, set a default group
    setGroups(
      updatedGroups.length > 0
        ? updatedGroups
        : [{ operator: "AND", filters: [] }]
    );

    // Fix: Use an if statement instead of an unused ternary
    if (updatedGroups.length === 0) {
      refetchTikcedBoardData();
      setAdvancedFilters(null);
    }
  };

  return (
    <Container>
      <span className="heading">Advanced Filters</span>

      {getErrorCount() > 0 && (
        <div className="error-total">
          <BiSolidError />
          <span>{getErrorCount()} fields with errors</span>
        </div>
      )}

      {!groups ||
      groups.length === 0 ||
      groups.every((group) => !group?.filters?.length) ? (
        // Show "no filters" message when there are no groups or no filters applied
        <div className="not-filter-text-wrapper">
          <span className="not-filter-text">
            This view doesn't have any advanced filters.
          </span>
          <span className="not-filter-text">Select a filter to begin.</span>
        </div>
      ) : (
        <>
          {groups.map((group, groupIndex) => (
            <>
              <div key={groupIndex} className="group-wrapper">
                <div className="header">
                  <span className="name">
                    {groupIndex === 0 ? "Group 1" : `Group ${groupIndex + 1}`}
                  </span>

                  <span
                    className="icon"
                    onClick={() => handleDeleteGroup(groupIndex)}
                  >
                    <RiDeleteBin6Fill color="rgb(76, 187, 203)" />
                  </span>
                </div>

                {group.filters.map((filter, filterIndex) => (
                  <div
                    style={{
                      border:
                        errors[groupIndex]?.[filterIndex] &&
                        "1px solid #fe6a7c",
                    }}
                    className="outer-div"
                    key={filterIndex}
                  >
                    <div className="headerr">
                      {filterIndex > 0 ? (
                        <span className="name">AND</span>
                      ) : (
                        <span className="name"></span>
                      )}

                      <span
                        className="icon"
                        onClick={() =>
                          handleDeleteFilter(groupIndex, filterIndex)
                        }
                      >
                        <RiDeleteBin6Fill color="rgb(76, 187, 203)" />
                      </span>
                    </div>
                    <span className="text">
                      <span>{filter?.field?.name}</span>
                      <span>
                        {" " + filter?.operator?.replace(/_/g, " ") + " "}
                      </span>
                      {filter?.value ? (
                        <span className="name">
                          {Array.isArray(filter.value)
                            ? filter.value.map((item, index) => (
                                <span key={index}>
                                  {item.name ? item.name : item}
                                  {index < filter.value.length - 1 && ", "}
                                </span>
                              ))
                            : filter?.field?.identifier === "contact_id" ||
                              filter?.field?.identifier === "user_id"
                            ? `${filter?.value?.first_name || ""} ${
                                filter?.value?.last_name || ""
                              }`
                            : filter?.field?.identifier === "company_id"
                            ? filter?.value?.name
                            : filter?.value}
                        </span>
                      ) : null}
                    </span>

                    {errors[groupIndex]?.[filterIndex] && (
                      <span className="error">
                        {errors[groupIndex][filterIndex]}
                      </span>
                    )}
                  </div>
                ))}

                <div className="outer-div-btn">
                  <div
                    onClick={() => {
                      setSelectedProperty(null);
                      setSelectedGroupIndex(groupIndex);
                    }}
                    className="btn add-filter-btn"
                  >
                    <FaPlus />
                    <span>Add Filter</span>
                  </div>
                </div>
              </div>

              <div className="sprtor">
                <span className="sprtor-line"></span>
                <span className="sprtor-text">OR</span>
                <span className="sprtor-line"></span>
              </div>
            </>
          ))}

          {/* Show "Add Group" button only when filters exist */}
          {groups.some((group) => group?.filters?.length > 0) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100%",
              }}
            >
              <div className="outer-div-btn">
                <div onClick={addGroup} className="btn">
                  <FaPlus />
                  <span>Add Group</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default TicketFilters;
