import React, { useEffect } from "react";
import FourthColumnContainer from "./styles";
import CompanyDetailSidebar from "../../componyFlowComponents/companyDetailSidebar/CompanyDetailSidebar";
import UserDetailSidebar from "../../userList/userDetailSidebar/UserDetailSidebar";
import useGetData from "../../../hooks/useGetData";
import { Skeleton } from "antd";
import Unknown from "./Unknown/Unknown";
import ColumnHerder from "./columnHerder/ColumnHerder";
import TicketDetails from "./ticketDetails/TicketDetails";

const FourthColumn = ({
  selectedEmailId,
  setSelectedEmailOrChat,
  refetchCount,
  refetchEmails,
  showDrawerContactDrawer,
  onCloseContactDrawer,
  selectedEmails,
  setSelectedEmails,
  selectedFolder,
  setRefetchEmail,
  id,
  selectedCompanyId,
  refetchEmail,
  refetchData,
  loading,
  email,
  isInitialLoadThread,
  setChatThread,
  setIsInitialLoadThread,
  refetchThread,
}) => {
  // const {
  //   data: email,
  //   error,
  //   loading,
  //   refetchData,
  // } = useGetData(`support/emails/${selectedEmailId}`)

  useEffect(() => {
    if (setRefetchEmail) {
      setRefetchEmail(refetchData);
    }
  }, [refetchEmails, setRefetchEmail]);

  return (
    <FourthColumnContainer>
      {loading && isInitialLoadThread ? (
        <div style={{ minWidth: "200px", padding: "12px" }}>
          <Skeleton active rows={15} />
        </div>
      ) : selectedEmailId !== null ? (
        <>
          <div className="header">
            <ColumnHerder
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
              selectedEmailId={selectedEmailId}
              refetchEmails={refetchEmails}
              refetchCount={refetchCount}
              setSelectedEmailOrChat={setSelectedEmailOrChat}
              email={email}
              selectedFolder={selectedFolder}
              id={id}
            />
          </div>

          <TicketDetails
            email={email}
            userID={email?.data?.contact_id}
            selectedEmailId={selectedEmailId}
            showDrawerContactDrawer={showDrawerContactDrawer}
            onCloseContactDrawer={onCloseContactDrawer}
            refetchData={refetchData}
            selectedCompanyId={selectedCompanyId}
            selectedFolder={selectedFolder}
            senderName={email?.data?.sender_name}
            refetchEmail={refetchEmail}
            setChatThread={setChatThread}
            setIsInitialLoadThread={setIsInitialLoadThread}
            refetchThread={refetchThread}
          />
        </>
      ) : null}
    </FourthColumnContainer>
  );
};

export default FourthColumn;
