import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* width: 100%; */
  background-color: #f5f7fa;
  padding: 10px 23px;
  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .msg-text {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #5cb85c;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
    width: 100%;
    animation: blink 1.5s infinite;
  }
  .search {
    display: flex;
    width: 208px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background-color: rgb(248 252 252);
    border: 1px solid rgb(178, 235, 242);
    height: 32px;

    .input {
      background-color: rgb(248 252 252);
      overflow: hidden;
      border: none;
      outline: none;
      max-width: 155px;
    }
    .input::placeholder {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }
    .icon {
      width: 16px;
      height: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
    .searchable-select {
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
      width: 200px;
      .ant-select-selection-search {
        /* inset-inline-start: 12px !important; */
        top: -1px !important;
      }
    }

    .menu-items,
    .btn-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .ant-space-item {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */

        span {
          svg {
            /* width: 16px;
          height: 16px; */
          }
        }
      }
    }
  }
`;

export { Container };
