import React from "react";
import { Container } from "./styles";
import { Form, Input, Switch } from "antd";
import GetLogo from "../../getlogo/getlogo";

const LiveAnsweringSetting = ({}) => {
  const [form] = Form.useForm();

  return (
    <Container>
      <span className="title">Live Answering </span>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Pricing</span>
          </div>
          {/* <span className="right">Test Connection</span> */}
        </div>

        <div className="make-row">
          <div className="form-main" style={{ marginTop: "10px" }}>
            <div className="form">
              <Form form={form}>
                <Form.Item
                  label="Plan"
                  name="easy_post_key"
                  className="form-items"
                >
                  <Input className="input" placeholder="" />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="form-main" style={{ marginTop: "10px" }}>
            <div className="form">
              <Form form={form}>
                <Form.Item
                  label="Limit"
                  name="easy_post_key"
                  className="form-items"
                >
                  <Input className="input" placeholder="100" suffix="min" />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="form-main" style={{ marginTop: "10px" }}>
            <div className="form">
              <Form form={form}>
                <Form.Item
                  label="Extra Time Fee per MInute"
                  name="easy_post_key"
                  className="form-items"
                >
                  <Input className="input" placeholder="02" suffix="$" />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        <div className="make-row">
          <div className="form-main" style={{ marginTop: "10px" }}>
            <div className="form">
              <Form form={form}>
                <Form.Item
                  label="Plan"
                  name="easy_post_key"
                  className="form-items"
                >
                  <Input className="input" placeholder="" />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="form-main" style={{ marginTop: "10px" }}>
            <div className="form">
              <Form form={form}>
                <Form.Item
                  label="Limit"
                  name="easy_post_key"
                  className="form-items"
                >
                  <Input className="input" placeholder="100" suffix="min" />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="form-main" style={{ marginTop: "10px" }}>
            <div className="form">
              <Form form={form}>
                <Form.Item
                  label="Extra Time Fee per MInute"
                  name="easy_post_key"
                  className="form-items"
                >
                  <Input className="input" placeholder="02" suffix="$" />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Call Routing Tags</span>
          </div>
          {/* <span className="right">Test Connection</span> */}
        </div>

        <div className="make-row">
          <div className="tag">
            <span className="tag-text">English</span>
            <span className="edit-tag">{GetLogo("edit")}</span>
          </div>

          <div className="tag">
            <span className="tag-text">Spanish</span>
            <span className="edit-tag">{GetLogo("edit")}</span>
          </div>
        </div>

        <div className="make-row">
          <div className="tag">
            <span className="tag-text">Bsuiness Hours EST</span>
            <span className="edit-tag">{GetLogo("edit")}</span>
          </div>

          <div className="tag">
            <span className="tag-text">Legal Holidays</span>
            <span className="edit-tag">{GetLogo("edit")}</span>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Call Handling</span>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Time Before Call is Marked Unavaialble "
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="05" suffix="min" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Time Before Call is Escalated"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="02" suffix="min" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LiveAnsweringSetting;
