import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .page-header {
    background: white;
    position: fixed;
    top: 0;
    left: ${(props) => (!props.isSidebarCollapsed ? '270px' : '68px')};
    width: ${(props) => (!props.isSidebarCollapsed ? 'calc(100% - 270px)' : 'calc(100% - 70px)')};
  }

  .main-page {
    display: flex;
    width: 100%;
    height: calc(100vh - 110px);
    padding: 0px 40px;
    padding-right: 0px;
    gap: 56px;
    margin-top: 110px; 
  }

  .sidebar {
    position: fixed;
    left: ${(props) => (!props.isSidebarCollapsed ? '298px' : '100px')};
    top: 125px;
    width: 200px;
    height: calc(100vh - 80px); 
  }

  .body {
    margin-left: 250px;
    width: calc(100% - 250px);
    height: calc(100% - 0px);
    overflow-y: auto;
    background: white; 
    padding: 16px 0px;
  }
`;

export { MainContainer };
