import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai"; // Trash icon
import { MdReportProblem } from "react-icons/md"; // Spam icon
import "./styles";
import { Container, AvatarPlaceholder } from "./styles"; // Import new styled component
import { InstanceWithAuth } from "../../../../App";
import { Popconfirm, Skeleton } from "antd";
import { PiArrowArcRightBold } from "react-icons/pi";
import { LuExternalLink } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NameAvatar from "../../../../common/NameAvatar";
const ColumnHeader = ({
  selectedEmailId,
  refetchEmails,
  setSelectedEmailOrChat,
  email,
  showDrawerContactDrawer,
  selectedEmails,
  selectedFolder,
  loadingEmailThread,
  isInitialLoadThread,
}) => {
  const navigate = useNavigate();
  const { app } = useSelector((state) => state);
  const firstName = email?.data?.contact?.first_name || "";
  const lastName = email?.data?.contact?.last_name || "";
  const senderName = email?.data?.sender_name || "";
  const avatar = email?.data?.contact?.avatar;
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };
  const getUnknownInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };
  console.log(
    app?.allOpenConversationDetails,
    "app?.allOpenConversationDetails"
  );
  let getInitialsName = (name) => {
    const names = name?.split(" ");
    const initials = names?.map((n) => n[0])?.join("");
    return initials?.toUpperCase();
  };
  return (
    <Container>
      {loadingEmailThread && isInitialLoadThread ? (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton.Avatar active size="defual" shape="circle" />
          <Skeleton.Input active size="large" />
        </div>
      ) : (
        <>
          {!app?.allOpenConversationDetails?.contact_id ? (
            <>
              {avatar ? (
                <img src={avatar} alt="Contact Avatar" />
              ) : (
                <AvatarPlaceholder>
                  {getUnknownInitials("Unknown", "Visitor")}
                </AvatarPlaceholder>
              )}
              <div className="details-div">
                <span className="contact-name">Unknown Visitor</span>
                <div className="bottom-line-div">
                  <span
                    onClick={showDrawerContactDrawer}
                    style={{ cursor: "pointer" }}
                    className="company-name"
                  >
                    + Add contact
                  </span>
                  <span className="dot"></span>
                  {app?.allOpenConversationDetails?.closed_at ? (
                    <>
                      <span className="from">Closed on</span>
                      <span className="from">
                        {formatDate(app?.allOpenConversationDetails?.closed_at)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="from">Opened on</span>
                      <span className="from">
                        {formatDate(
                          app?.allOpenConversationDetails?.created_at
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {avatar ? (
                <img src={avatar} alt="Contact Avatar" />
              ) : (
                <AvatarPlaceholder>
                  <NameAvatar
                    val={30}
                    userName={
                      app?.allOpenConversationDetails?.contact_id &&
                      app?.allOpenConversationDetails?.Model === "SupportEmails"
                        ? `${firstName || ""} ${lastName || ""}`.trim()
                        : app?.allOpenConversationDetails?.contact_id &&
                          app?.allOpenConversationDetails?.Model === "LiveChat"
                        ? `${
                            app?.allOpenConversationDetails?.contact
                              ?.first_name || ""
                          } ${
                            app?.allOpenConversationDetails?.contact
                              ?.last_name || ""
                          }`.trim()
                        : app?.allOpenConversationDetails?.sender_name
                        ? app?.allOpenConversationDetails?.sender_name
                        : app?.allOpenConversationDetails?.company_id
                        ? app?.allOpenConversationDetails?.company_name
                        : app?.allOpenConversationDetails?.guest_name || ""
                    }
                    fontSize={16}
                  ></NameAvatar>
                </AvatarPlaceholder>
              )}
              <div className="details-div">
                <span className="contact-name">
                  {app?.allOpenConversationDetails?.contact_id &&
                  app?.allOpenConversationDetails?.Model === "SupportEmails"
                    ? `${firstName || ""} ${lastName || ""}`.trim()
                    : app?.allOpenConversationDetails?.contact_id &&
                      app?.allOpenConversationDetails?.Model === "LiveChat"
                    ? `${
                        app?.allOpenConversationDetails?.contact?.first_name ||
                        ""
                      } ${
                        app?.allOpenConversationDetails?.contact?.last_name ||
                        ""
                      }`.trim()
                    : app?.allOpenConversationDetails?.sender_name
                    ? app?.allOpenConversationDetails?.sender_name
                    : app?.allOpenConversationDetails?.company_id
                    ? app?.allOpenConversationDetails?.company_name
                    : app?.allOpenConversationDetails?.guest_name || ""}
                </span>
                <div className="bottom-line-div">
                  {app?.allOpenConversationDetails?.company_id !== null && (
                    <>
                      <span className="from">from</span>
                      <span className="company-name">
                        <TruncateText
                          text={app?.allOpenConversationDetails?.company?.name}
                        ></TruncateText>
                      </span>
                      <span
                        className="link"
                        onClick={() => {
                          if (email?.data?.company?.id) {
                            const url = `/companies/${app?.allOpenConversationDetails?.company?.id}`;
                            window.open(url, "_blank");
                          } else {
                            console.error("Company ID is missing");
                          }
                        }}
                      >
                        <LuExternalLink
                          size={14}
                          color="var(--Gray-100, #556373)"
                        />
                      </span>

                      <span className="dot"></span>
                    </>
                  )}
                  {/* some work done */}
                  {/* <div className="from">Closed on</div>
                  <div
                    className="from-date"
                    style={{ width: "75px", whiteSpace: "nowrap" }}
                  >
                    Sep 4, 2024
                  </div> */}

                  {app?.allOpenConversationDetails?.closed_at ? (
                    <>
                      <span className="from">Closed on</span>
                      <span className="from">
                        {formatDate(app?.allOpenConversationDetails?.closed_at)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="from">Opened on</span>
                      <span className="from">
                        {formatDate(app?.allOpenConversationDetails?.opened_at)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ColumnHeader;
