import React from "react";
import { Container } from "./styles";

const PageHeader = ({}) => {
  return (
    <Container>
      <span className="title">Admin Settings</span>

      <button className="save-btn">Add New</button>
    </Container>
  );
};

export default PageHeader;
