import React, { createContext, useContext } from "react";
import { message } from "antd";

const GlobalAlertMessageContext = createContext(null);

export const GlobalAlertMessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = ({ type, content }) => {
    const defaultMessages = {
      success: "Operation completed successfully!",
      error: "An error occurred. Please try again.",
    };

    const displayContent = content || defaultMessages[type] || "Notification";

    messageApi.open({ type, content: displayContent });
  };

  return (
    <GlobalAlertMessageContext.Provider value={{ showMessage }}>
      {contextHolder}
      {children}
    </GlobalAlertMessageContext.Provider>
  );
};

export const useGlobalAlertMessage = () => {
  const context = useContext(GlobalAlertMessageContext);
  if (!context) {
    throw new Error(
      "useGlobalAlertMessage must be used within a GlobalAlertMessageProvider"
    );
  }
  return context;
};
