import React, { useEffect, useRef, useState } from "react";
import { ChatMessageContainer, AttachmentList } from "./styles";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Avatar from "../avatar/avatar";
import CustomTooltip from "../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import MessageDetailsTooltip from "./messageDetailsTooltip/MessageDetailsTooltip";
import GetLogo from "../../../getlogo/getlogo";
import { GoReply } from "react-icons/go";
import { IoReturnUpForward } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
function ChatMessage({
  message,
  sender,
  timestamp,
  imageName,
  emailData,
  currentEmployeeImage,
  time,
  attachments,
  sender_avatar,
  handleForwardMail,
  handleReplyEmail,
  mailDetails,
  recipients,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showFaded, setShowFaded] = useState(false);
  const [tooltipText, setTooltipText] = useState("Click to copy");
  const { app } = useSelector((state) => state);
  const { messageId } = useParams();
  const [highlight, setHighlight] = useState(false);
  const containerRef = useRef(null);
  const [showToggleButton, setShowToggleButton] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  // Split message at the email history marker
  const splitIndex = message?.indexOf("[--!!email-history!!--]");
  const hasHistory = splitIndex !== -1;

  // Extract parts
  const beforeHistory = hasHistory
    ? message?.substring(0, splitIndex)
    : message;
  const afterHistory = hasHistory ? message?.substring(splitIndex) : "";

  useEffect(() => {
    if (containerRef.current) {
      setShowToggleButton(containerRef?.current?.clientHeight > 170);
    }
  }, [message, expanded]);

  useEffect(() => {
    if (message?.length > beforeHistory && !expanded) {
      setShowFaded(true);
    } else {
      setShowFaded(false);
    }
  }, [expanded, message?.length]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const TruncateText = ({ text }) => {
    const maxLength = 15;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const handleCopy = (emailData) => {
    const url = `${window.location.origin}/support/${app?.currentActiveInboxTabKey}/${emailData?.id}/${mailDetails?.content?.id}`;
    navigator.clipboard.writeText(url);
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("Click to Copy");
    }, 2000);
  };

  useEffect(() => {
    console.log("messageObject", message);
    if (mailDetails?.content?.id?.toString() === messageId) {
      setHighlight(true);
      // const timer = setTimeout(() => {
      //   setHighlight(false);
      // }, 1000);

      // return () => clearTimeout(timer);
    }
  }, [messageId, mailDetails]);

  return (
    <ChatMessageContainer
      ref={containerRef}
      expanded={expanded}
      showFaded={showFaded}
      showToggleButton={showToggleButton}
      sender={sender}
      isHighlightedMessage={
        mailDetails?.content?.id?.toString() === messageId && highlight
      }
    >
      <div className="email-item">
        <div className="email-header">
          <Avatar sender_avatar={sender_avatar} sender={sender} />
          <div className="email-details">
            <div className="email-top-row">
              <div className="left-container">
                <span className="email-sender">{sender}</span>
                {showTooltip && (
                  <MessageDetailsTooltip
                    to={emailData.receiver_email}
                    subject={emailData.subject}
                    from={emailData.from_email}
                    date={timestamp}
                    closeTooltip={() => setShowTooltip(false)}
                    marginTop={50}
                    recipients={recipients}
                    mailDetails={mailDetails}
                  >
                    <span className="tooltip-trigger">Details</span>
                  </MessageDetailsTooltip>
                )}
                <div
                  className="arrow-down"
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  {showTooltip ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                </div>
              </div>

              <div className="make-row">
                <CustomTooltip marginTop="-15px" text={timestamp}>
                  <span className="email-time">{time}</span>
                </CustomTooltip>
                <CustomTooltip marginTop="-15px" text={"Reply"}>
                  <span className="back-icon">
                    <GoReply onClick={() => handleReplyEmail(emailData)} />
                  </span>
                </CustomTooltip>
                <CustomTooltip marginTop="-15px" text={"Forward"}>
                  <span className="back-icon">
                    <IoReturnUpForward
                      onClick={() => handleForwardMail(mailDetails)}
                    />
                  </span>
                </CustomTooltip>

                <CustomTooltip marginTop="-15px" text={tooltipText}>
                  <div className="icon" onClick={() => handleCopy(emailData)}>
                    {GetLogo("copy")}
                  </div>
                </CustomTooltip>
              </div>
            </div>

            <div className={`email-subject ${expanded ? "expanded" : ""}`}>
              {/* Render first part of the message */}
              <div dangerouslySetInnerHTML={{ __html: beforeHistory }} />

              {/* Toggle button (three dots) to show history */}
              {hasHistory && (
                <span
                  className="toggle-history-button"
                  onClick={() => setShowHistory(!showHistory)}
                >
                  <HiDotsHorizontal />
                </span>
              )}

              {/* Show email history when toggled */}
              {showHistory && (
                <div dangerouslySetInnerHTML={{ __html: afterHistory }} />
              )}
            </div>

            <AttachmentList>
              {attachments.map((file, index) => (
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="AttachmentItem"
                >
                  <div className="file-name">
                    <img className="file-image" src={file?.url} alt=" " />
                    <TruncateText text={file.filename}></TruncateText>
                  </div>
                  <span className="file-size">{file.formatted_size}</span>
                </a>
              ))}
            </AttachmentList>

            {showToggleButton && (
              <div className="toggle-button">
                <div className="show-more" onClick={toggleExpand}>
                  {expanded ? (
                    <>
                      Show less{" "}
                      <span
                        style={{
                          paddingTop: "4px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TiArrowSortedUp />
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                    >
                      <span> Show more </span>
                      <span
                        style={{
                          padding: 0,
                          marginBottom: "-7px",
                        }}
                      >
                        <TiArrowSortedDown />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ChatMessageContainer>
  );
}

export default ChatMessage;
