import React, { useState, useRef, useEffect } from "react";
import { Collapse, Skeleton } from "antd";
import GetLogo from "../../../../getlogo/getlogo";
import DataNotFound from "../../../../../common/DataNotFound";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CompanyCards, Container, ContentContainer } from "./styles";
import "./styles";
import useGetData from "../../../../../hooks/useGetData";
import {
  handSelectCompanyFlowTab,
  handleOpnedCompnayDetailId,
  handleIsCommingFromTikcetDetail,
} from "../../../../../redux/app/appSlice";

const { Panel } = Collapse;

const Support = ({ userID, app }) => {
  const [collapsedKey, setCollapsedKey] = useState([]);
  const [showAllOpenTickets, setShowAllOpenTickets] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bottomRef = useRef(null);
  const {
    data: tickets,
    error: ticketsError,
    loading,
  } = useGetData(
    `support/tickets?order_by=created_at&order=DESC&company_id=${userID}`
  );
  useEffect(() => {
    if (app?.opnedCompnayDetailId && app?.isCommingFromTikcetDegtail) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setCollapsedKey(["1"]); // Open the panel with key="1"
      dispatch(handleIsCommingFromTikcetDetail(false));
      dispatch(handleOpnedCompnayDetailId(""));
    }
  }, []);

  const TruncateText = ({ text }) => {
    const maxLength = 50;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };
  const handleShowMore = () => {
    setShowAllOpenTickets(true);
  };
  const handleRedirect = () => {
    dispatch(handSelectCompanyFlowTab("10"));
    navigate(`/companies/${userID}`);
  };
  const openTickets = tickets?.data?.filter((ticket) => ticket.is_closed === 0);
  const filteredTickets = showAllOpenTickets
    ? openTickets
    : openTickets?.slice(0, 4);
  const hasMoreOpenTickets = openTickets?.length > 4;
  const handelViewTicket = (ticket, userID) => (
    dispatch(handleOpnedCompnayDetailId(userID)),
    navigate(`/tickets/${ticket?.id}`)
  );
  return (
    <>
      <Container open={collapsedKey.includes("1")}>
        <Collapse
          accordion
          className="account-collapase"
          activeKey={collapsedKey} // Controlled by collapsedKey state
          onChange={(keys) => setCollapsedKey(keys)} // Update state when panel is toggled
        >
          <Panel header="Support" key="1" className="account-panel">
            <ContentContainer>
              {loading ? (
                <Skeleton active />
              ) : (
                <div className="tickets-container">
                  <div
                    className="tickets"
                    style={{
                      width: "100%",
                    }}
                  >
                    {filteredTickets?.map((ticket, index) => (
                      <div key={index} className="ticket">
                        <span className="name">{ticket?.name}</span>
                        <span className="des">
                          <TruncateText text={ticket?.description} />
                        </span>
                        <div className="make-row">
                          <div className="left">
                            <span className="priority-label">Priority: </span>
                            <span className="priority">{ticket?.priority}</span>
                          </div>
                          <div className="right">
                            <div className="active">
                              <span className="text">Open</span>
                            </div>
                            <div className="view-btn">
                              <span
                                className="text"
                                onClick={() => handelViewTicket(ticket, userID)}
                              >
                                View
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {tickets?.data?.length === 0 && !loading ? (
                <DataNotFound label={"No Task and Ticket Found"} />
              ) : null}
              {!loading && (
                <div className="view-all-container">
                  {hasMoreOpenTickets && !showAllOpenTickets && (
                    <button className="show-more" onClick={handleShowMore}>
                      Show More
                    </button>
                  )}
                  {tickets?.data?.length > 0 && (
                    <button className="view-all" onClick={handleRedirect}>
                      View All Tickets
                    </button>
                  )}
                </div>
              )}
            </ContentContainer>
          </Panel>
        </Collapse>
      </Container>
      <div ref={bottomRef}></div>
    </>
  );
};

export default Support;
