import styled from "styled-components";

export const Container = styled.div`
  width: 200px;
  gag: 4px;
`;

export const SidebarItem = styled.div`
padding: 11px 16px 11px 16px;
border-radius: 3px 0px 0px 0px;
opacity: 0px;
font-family: Outfit;
font-size: 16px;
font-weight: 400;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-bottom: 4px;
  cursor: pointer;
  color: "#2B3746";
  background: ${({ isActive }) => (isActive ? "var(--Gray-5, #5563730D)" : "transparent")};
  border-radius: 4px;

  &:hover {
    background: var(--Gray-5, #5563730D);
  }
    display: flex;
    aling-items: center;
gap: 20px;
.icon {
margin-top: 3px;
}
`;

export const Dropdown = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const DropdownItem = styled.div`
  font-size: 14px;
  color: var(--Gray-70, #556373B2);
  background: ${({ isActive }) => (isActive ? "var(--Gray-5, #5563730D)" : "transparent")};
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;

  font-family: Outfit;
font-size: 14px;
font-weight: 300;
line-height: 16.8px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;


  &:hover {
    background: ${({ isActive }) => (isActive ? "var(--Gray-5, #5563730D)" : "transparent")};
  }
`;
