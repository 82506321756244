import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  height: 100%;
  padding: 0px !important;
  margin: 0px !important;

  .left {
    flex: 1;
    border-right: 1px solid #e3eced;
    padding-right: 10px;
  }

  .right {
    flex: 1;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 30px;
  }
`;

export { Container };
