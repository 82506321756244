import React from "react";
import { Container, RadioContainer } from "./styles";
import Image1 from "./images/image_1.svg";
import Image2 from "./images/image_2.svg";
import Image3 from "./images/image_3.svg";
import Image4 from "./images/image_4.svg";
import { Form, Input, Switch } from "antd";

const GeneralSettingsPage = ({}) => {
  const [form] = Form.useForm();

  return (
    <Container>
      <span className="title">API Management </span>

      <div className="setting-card">
        <div className="card-header">
          <div className="left">
            <img className="product-logo" src={Image3} />
            <span className="product-name">Easypost</span>
          </div>
          <span className="right">Test Connection</span>
        </div>

        <div className="form-main" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="API Key"
                name="easy_post_key"
                className="form-items"
              >
                <Input.Password
                  className="input"
                  placeholder="****************"
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <RadioContainer>
          <span className="label">Enable/Disable Easypost</span>
          <Form
            form={form}
            // onValuesChange={handleFormItemChange}
          >
            <Form.Item
              valuePropName="checked"
              name="is_monthly"
              className="options"
            >
              <Switch
              // onChange={onChangeMonthlyBilling}
              />
            </Form.Item>
          </Form>
        </RadioContainer>
      </div>

      <div className="setting-card">
        <div className="card-header">
          <div className="left">
            <img className="product-logo" src={Image2} />
            <span className="product-name">Chargify</span>
          </div>

          <span className="right">Test Connection</span>
        </div>

        <div className="form-main" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="API Key"
                name="chargify_key"
                className="form-items"
              >
                <Input.Password
                  className="input"
                  placeholder="****************"
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <RadioContainer>
          <span className="label">Enable/Disable Chargify</span>
          <Form
            form={form}
            // onValuesChange={handleFormItemChange}
          >
            <Form.Item
              valuePropName="checked"
              name="is_monthly"
              className="options"
            >
              <Switch
              // onChange={onChangeMonthlyBilling}
              />
            </Form.Item>
          </Form>
        </RadioContainer>
      </div>

      <div className="setting-card">
        <div className="card-header">
          <div className="left">
            <img className="product-logo" src={Image4} />
            <span className="product-name">Stripe</span>
          </div>
          <span className="right">Test Connection</span>
        </div>

        <div className="form-main" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="API Key"
                name="stripe_key"
                className="form-items"
              >
                <Input.Password
                  className="input"
                  placeholder="****************"
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <RadioContainer>
          <span className="label">Enable/Disable Stripe</span>
          <Form
            form={form}
            // onValuesChange={handleFormItemChange}
          >
            <Form.Item
              valuePropName="checked"
              name="is_monthly"
              className="options"
            >
              <Switch
              // onChange={onChangeMonthlyBilling}
              />
            </Form.Item>
          </Form>
        </RadioContainer>
      </div>

      <div className="setting-card">
        <div className="card-header">
          <div className="left">
            <img className="product-logo" src={Image1} />
            <span className="product-name">Twilio</span>
          </div>
          <span className="right">Test Connection</span>
        </div>

        <div className="form-main" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="API Key"
                name="twilio_key"
                className="form-items"
              >
                <Input.Password
                  className="input"
                  placeholder="****************"
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <RadioContainer>
          <span className="label">Enable/Disable Twilio</span>
          <Form
            form={form}
            // onValuesChange={handleFormItemChange}
          >
            <Form.Item
              valuePropName="checked"
              name="is_monthly"
              className="options"
            >
              <Switch
              // onChange={onChangeMonthlyBilling}
              />
            </Form.Item>
          </Form>
        </RadioContainer>
      </div>
    </Container>
  );
};

export default GeneralSettingsPage;
