import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Button, Input } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner, CraeteTicketContainer } from "./styles";
import TextArea from "antd/lib/input/TextArea";
import { InstanceWithAuth } from "../../../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { handleAddOpenConversationDetails } from "../../../../../../redux/app/appSlice";
import { useParams } from "react-router-dom";
import { useGlobalAlertMessage } from "../../../../../../hooks/useAlertMessage";

const { Option } = Select;

const CraeteTicket = ({
  isPipelineModelOpen,
  handleCancel,
  handleOk,
  pipelines,
  selectedPipeline,
  stages,
  onPipelineChange,
  selectedEmailId,
  refetchData,
  email,
  refetchEmail,
  setChatThread,
  setIsInitialLoadThread,
  refetchThread,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth, app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { mailID } = useParams();

  const handleSave = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsSaving(true);
        try {
          const type = email?.data?.type || "";
          console.log("email type:", type);

          const url =
            type === "SupportEmails"
              ? "support/tickets/email-to-ticket"
              : "live-chat/ticket";

          const response = await InstanceWithAuth.post(
            `${url}/${selectedEmailId}`,
            {
              support_pipeline_id: values.pipeline,
              support_pipeline_stage_id: values.stage,
              name: values.ticketName,
              description: values.description,
              priority: values.priority,
              user_id: auth?.userObj?.id,
              email: values.email,
              ...(email?.data?.is_unknown_visitor && {
                first_name: values.firstName,
                last_name: values.lastName,
              }),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${auth?.userObj?.api_token}`,
              },
            }
          );

          if (response?.status === 201 || response?.status === 201) {
            form.resetFields();
            if (email?.data?.type === "SupportEmails") {
              refetchEmail();
              handleOk();
              const response = await InstanceWithAuth.get(
                `support/emails/${selectedEmailId}`,
                {
                  headers: {
                    Authorization: `Bearer ${auth?.userObj?.api_token}`,
                  },
                }
              );
              if (response.status === 200 || response.status === 201) {
                dispatch(
                  handleAddOpenConversationDetails(response?.data?.data)
                );
              }
            } else {
              try {
                const response = await InstanceWithAuth.get(
                  `live-chat/${selectedEmailId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${auth?.userObj?.api_token}`,
                    },
                  }
                );
                if (response.status === 200 || response.status === 201) {
                  // handleOk();
                  refetchThread();
                  setChatThread(response?.data);
                  setIsInitialLoadThread(false);
                  dispatch(
                    handleAddOpenConversationDetails(response?.data?.data)
                  );
                }
              } catch (error) {}
            }

            showMessage({
              type: "success",
              content: response?.data?.message,
            });
          }
        } catch (error) {
          showMessage({
            type: "error",
            content: error?.response?.data?.message,
          });
        } finally {
          setIsSaving(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  // useEffect(() => {
  //   if (selectedPipeline) {
  //     form.setFieldsValue({ pipeline: selectedPipeline.id });
  //   }
  // }, [selectedPipeline]);

  useEffect(() => {
    if (
      app?.allOpenConversationDetails?.sender_name ||
      app?.allOpenConversationDetails?.receiver_name
    ) {
      if (app?.allOpenConversationDetails?.is_sent === 1) {
        const fullName = app?.allOpenConversationDetails?.receiver_name || "";
        const nameParts = fullName ? fullName.split(" ") : [];

        const firstName = nameParts[0] || "";
        const lastName = nameParts.slice(1).join(" ") || "";

        form.setFieldsValue({
          first_name: firstName,
          last_name: lastName,
          email: app?.allOpenConversationDetails?.receiver_email || "",
        });
      } else {
        const fullName = app?.allOpenConversationDetails?.sender_name || "";
        const nameParts = fullName ? fullName.split(" ") : [];

        const firstName = nameParts[0] || "";
        const lastName = nameParts.slice(1).join(" ") || "";

        form.setFieldsValue({
          first_name: firstName,
          last_name: lastName,
          email: app?.allOpenConversationDetails?.sender_email || "",
        });
      }
    } else {
      form.setFieldsValue({
        firstName: app?.allOpenConversationDetails?.contact_id
          ? `${
              app?.allOpenConversationDetails?.contact?.first_name || ""
            }`.trim()
          : app?.allOpenConversationDetails?.guest_id
          ? `${app?.allOpenConversationDetails?.guest?.first_name || ""}`.trim()
          : "",
        lastName: app?.allOpenConversationDetails?.contact_id
          ? `${
              app?.allOpenConversationDetails?.contact?.last_name || ""
            }`.trim()
          : app?.allOpenConversationDetails?.guest_id
          ? `${app?.allOpenConversationDetails?.guest?.last_name || ""}`.trim()
          : "",
        email: app?.allOpenConversationDetails?.contact_id
          ? `${app?.allOpenConversationDetails?.contact?.email || ""}`.trim()
          : app?.allOpenConversationDetails?.guest_id
          ? `${app?.allOpenConversationDetails?.guest?.email || ""}`.trim()
          : "",
      });
    }
  }, [app?.allOpenConversationDetails, form]);

  return (
    <CraeteTicketContainer>
      <Form form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="form-container">
              {email?.data?.is_unknown_visitor ? (
                <>
                  <div className="form-main">
                    <div className="form">
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        className="form-items"
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name!",
                          },
                        ]}
                      >
                        <Input className="location" placeholder="First Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="form-main">
                    <div className="form">
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        className="form-items"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },
                        ]}
                      >
                        <Input className="location" placeholder="Last Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="form-main">
                    <div className="form">
                      <Form.Item
                        name="email"
                        label="Email"
                        className="form-items"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email",
                          },
                        ]}
                      >
                        <Input className="location" placeholder="Email" />
                      </Form.Item>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="pipeline"
                    label="Pipeline"
                    className="form-items"
                    rules={[
                      { required: true, message: "Please select a pipeline!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a pipeline"
                      onChange={onPipelineChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {pipelines?.map((pipeline) => (
                        <Option key={pipeline.id} value={pipeline.id}>
                          {pipeline.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="stage"
                    label="Stage"
                    rules={[
                      { required: true, message: "Please select a stage!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a stage"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {stages.map((stage) => (
                        <Option key={stage.id} value={stage.id}>
                          {stage.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="priority"
                    label="Priority"
                    rules={[
                      { required: true, message: "Please select a priority!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Set priority"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="Low">Low</Option>
                      <Option value="Medium">Medium</Option>
                      <Option value="High">High</Option>
                      <Option value="Very High">Very High</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="ticketName"
                    label="Ticket Name"
                    className="form-items"
                  >
                    <Input className="location" placeholder="Ticket Name" />
                  </Form.Item>
                </div>
              </div>

              <div className="welcome-message">
                <Form.Item
                  label="Ticket Description"
                  name="description"
                  className="form-items"
                  rules={[
                    { required: true, message: "Please enter a description!" },
                  ]}
                >
                  <TextArea
                    className="input"
                    placeholder="Ticket description here..."
                  />
                </Form.Item>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
              disabled={isSaving}
            >
              Close
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isSaving}
              className={isSaving ? "saving" : ""}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </CraeteTicketContainer>
  );
};

export default CraeteTicket;
