import Layouts from './layout/Layouts'
import axios from 'axios'
import './App.css'
export const InstanceWithAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'requested-lang': 109,
    Accept: 'application/json',
  },
})

function App() {
  return (
    <div className="App">
      <Layouts></Layouts>
    </div>
  )
}

export default App
