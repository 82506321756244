import styled from "styled-components";

const Container = styled.div`
  background: red;
  display: flex;
  

`;

export { Container };
