import styled from "styled-components";

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
    props.open
      ? "var(--Blue-100, #4cbbcb)"
      : "var(--Gray-5, rgba(85, 99, 115, 0.05))"};

        .ant-collapse-header-text {
          color: ${(props) =>
    props.open
      ? "var(--White-100, #fff)"
      : "var(--Dark-Gray-100, #2B3746)"};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
    props.open
      ? "var(--White-100, #fff)"
      : "var(--Dark-Gray-100, #2B3746)"};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 7px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 339px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  span {
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  button {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
  }

  .tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 7px;
    padding: 0;
    margin: 0;

    .ticket {
      border-radius: 8px;
      border: 1px solid #d0dee1;
      background: #fff;
      width: 100%;
      padding: 15px;
      margin: 0;
      .name {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .des {
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--Gray-100, #556373);
      }
      .make-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .left {
          display: flex;
          align-items: center;
          gap: 5px;
          .priority-label {
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: var(--Gray-100, #556373);
          }
          .priority {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .right {
          display: flex;
          align-items: center;
          gap: 5px;

          .active {
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: 10px;
            /* cursor: pointer; */
            border-radius: 4px;
            border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
            background: rgba(76, 203, 96, 0.2);
            .icon {
              svg {
                width: 8px;
                height: 8px;
              }
            }

            .text {
              color: var(--Dark-Gray-100, #2b3746);
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 14.4px */
            }
          }
          .in-active {
            display: flex;
            padding: 6px 8px;
            align-items: center;
            gap: 10px;
            /* cursor: pointer; */
            border-radius: 4px;
            border: 1px solid #ff7b7b;
            background: #ffbaba;
            .icon {
              svg {
                width: 8px;
                height: 8px;
                fill: #ff7b7b;
                fill-opacity: unset;
                circle {
                  fill: #ff7b7b;
                  fill-opacity: unset;
                  stroke: #ff7b7b;
                }
              }
            }

            .text {
              color: var(--Dark-Gray-100, #2b3746);
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 14.4px */
            }
          }
        }
      }
    }
  }
`;

export { Container, ContentContainer };
