import React, { Fragment, useState, useEffect } from "react";
import arrow from "../../assets/arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { handleloginUser } from "../../redux/auth/authSlice";
import { InstanceWithAuth } from "../../App";
import AuthHeader from "../../components/auth/AuthHeader";
import { useNavigate } from "react-router-dom";
import { Container, ImgConatiner, LoginFormContainer } from "./styles";
import Spinner from "../../common/Spinner";
import { Form, Input } from "antd";
import { handleLogin } from "../../redux/auth/authSlice";
import useGetData from "../../hooks/useGetData";
import { useGlobalMessage } from "../../hooks/useGlobalMessage";
import {
  handleCurrentPage,
  handleAddUserelectedLocation,
} from "../../redux/app/appSlice";

function Login() {
  const { auth } = useSelector((state) => state);
  const [passwordPlaceholder, setPasswordPlaceholder] = useState("Password ");
  const [usernamePlaceholder, setUsernamePlaceholder] = useState("Username");
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const {
    data: locationData,
    loading: locationDataLoading,
    error: locationDataError,
    refetchData: refetchLocationData,
  } = useGetData("locations/dropdown/get");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showMessage = useGlobalMessage();
  useEffect(() => {
    dispatch(handleCurrentPage("login"));
  }, []);

  useEffect(() => {
    // if (auth?.isLogin && false) {
    //   navigate('/employees/all-employees')
    //   dispatch(handleCurrentPage('Employees'))
    // }
  }, []);
  const handleUserNameFocus = () => {
    setUsernamePlaceholder("");
  };
  const handlePasswordNameFocus = () => {
    setPasswordPlaceholder("");
  };
  if (auth?.isLogin) {
    const defaultLocation = locationData?.data?.find(
      (location) => location?.key === auth?.userObj?.location_id
    );
    dispatch(handleAddUserelectedLocation(defaultLocation));
    if (auth?.userObj?.role_name == "Mailbox Operator") {
      navigate("/mailbox");
      dispatch(handleCurrentPage("Virtual Mailbox"));
    } else {
      navigate("/landing-page");
      dispatch(handleCurrentPage("landingPage"));
    }
  }

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("email", values?.username);
    formData.append("password", values?.password);
    try {
      setIsLoading(true);
      const response = await InstanceWithAuth.post("login", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201 || response.status === 200) {
        showMessage("success", "Successfully logged in!");
        refetchLocationData();
        setIsLoading(false);
        dispatch(handleLogin(response?.data));
        navigate("/landing-page");
        dispatch(handleCurrentPage("landingPage"));
      }
    } catch (error) {
      setIsLoading(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  return (
    <Fragment>
      <AuthHeader />
      <Container>
        <LoginFormContainer>
          <div className="title">Please, login to StartHub Admin.</div>
          <Form
            className="login-form"
            requiredMark={false}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <div className="inputs">
              <Form.Item
                className="form-item"
                label="Username"
                name="username"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                  { required: true, message: "Email is missing" },
                ]}
              >
                <Input
                  style={{
                    WebkitBoxShadow: "0 0 0px 1000px white inset",
                    boxShadow: "0 0 0px 1000px white inset",
                    backgroundColor: "white !important",
                  }}
                  placeholder={isUsernameFocused ? "" : usernamePlaceholder}
                  className="user-input"
                  onFocus={() => setIsUsernameFocused(true)}
                  onBlur={() => setIsUsernameFocused(false)}
                  autoComplete="off"
                  key={usernamePlaceholder}
                />
              </Form.Item>

              <Form.Item
                className="form-item"
                label="Password"
                name="password"
                rules={[{ required: true, message: "Password is missing" }]}
              >
                <Input.Password
                  style={{
                    WebkitBoxShadow: "0 0 0px 1000px white inset",
                    boxShadow: "0 0 0px 1000px white inset",
                    backgroundColor: "white !important",
                  }}
                  placeholder={isPasswordFocused ? "" : passwordPlaceholder}
                  className="user-input custom-input-password"
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  autoComplete="off"
                  key={passwordPlaceholder}
                />
              </Form.Item>

              <div
                onClick={() => navigate("/forget-password")}
                className="forget-password"
              >
                <p>Forgot Password?</p>
              </div>
            </div>

            <Form.Item>
              <button
                className="login-btn"
                type="submit"
                disabled={isLoading ? true : false}
              >
                <span className="login-label">
                  {isLoading ? <Spinner></Spinner> : "Login"}
                </span>
              </button>
            </Form.Item>
          </Form>
        </LoginFormContainer>
        <ImgConatiner>
          <div className="img-div">
            <div className="img-wrapper">
              <img src={arrow}></img>
            </div>
          </div>
        </ImgConatiner>
      </Container>
    </Fragment>
  );
}
export default Login;
