import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown, Space, Switch } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ColumnHeaderContainer } from "./styles";
import GetLogo from "../../../getlogo/getlogo";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { handleSupportSearchShow } from "../../../../redux/app/appSlice";
import { InstanceWithAuth } from "../../../../App";
import useGetData from "../../../../hooks/useGetData";

const ColumnHeader = ({
  department,
  setDepartment,
  departments,
  setSearchParm,
  isOnline,
  setIsOnline,
}) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (departments && departments.length > 0) {
      setSelectedDepartment(departments[0]?.name);
    }
  }, [departments]);

  const departmentMenu = (
    <Menu>
      {departments?.map((dept, index) => (
        <Menu.Item
          onClick={() => {
            setSelectedDepartment(dept.name);
            setDepartment(dept);
          }}
          key={index}
        >
          {dept.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    if (isSearchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputRef.current.value.trim() === "") {
          setIsSearchVisible(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSwitchChange = async (checked) => {
    setIsOnline(checked);

    try {
      const formData = new FormData();
      formData.append("chat_status", checked ? 1 : 0);

      const response = await InstanceWithAuth.post(
        `users/${auth?.userObj?.id}/chat-status`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        console.log("Chat status updated successfully");
      }
    } catch (error) {
      console.error("Failed to update chat status:", error);
    }
  };

  return (
    <ColumnHeaderContainer isSearchVisible={isSearchVisible}>
      <Dropdown overlay={departmentMenu} trigger={["click"]}>
        <Space className="btn-title">
          {selectedDepartment}
          {/* <DownOutlined /> */}
          <MdOutlineKeyboardArrowDown
            width="1rem"
            height="1rem"
            size="18px"
            style={{ marginTop: "7px" }}
          ></MdOutlineKeyboardArrowDown>
        </Space>
      </Dropdown>
      <div className="make-row">
        <div className="search">
          <div
            className="search-icon"
            onClick={() => dispatch(handleSupportSearchShow())}
          >
            <i className="fas fa-search">{GetLogo("searchIcon")}</i>
            <div className="search-title">Search</div>
          </div>
        </div>
      </div>

      <div className="make-row">
        <div className="make-row1">
          <span
            className="circle"
            style={{
              backgroundColor: isOnline ? "lightgreen" : "#2b3746",
            }}
          ></span>
          <span className="status">
            {isOnline ? "You're online" : "You're away"}
          </span>
        </div>
        <Switch
          className="switch"
          checked={isOnline}
          onChange={handleSwitchChange}
        />
      </div>
    </ColumnHeaderContainer>
  );
};

export default ColumnHeader;
