import React, { useEffect, useRef, useState } from "react";
import {
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
  ChatInputContainer,
} from "./styles";
import { IoReturnUpForward, IoClose } from "react-icons/io5";
import { FiPaperclip, FiSend } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown, MdSend } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

Quill.register("modules/emoji", Emoji);

const ThreadInput = ({
  threadQuotedMessage,
  setThreadQuotedMessage,
  handleSendMessage,
  setValue,
  value,
  sendingMessage,
  handleAttachmentsChange,
  selectedFiles,
  setSelectedFiles,
}) => {
  const fileInputRef = useRef(null);
  const editorRef = useRef(null);

  const handleEditorChange = (content) => {
    setValue(sanitizeMessage(content));
  };

  const sanitizeMessage = (htmlContent) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;

    Array.from(div.querySelectorAll("p")).forEach((p) => {
      if (!p.textContent.trim()) {
        p.remove();
      }
    });

    return div.innerHTML.trim();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prev) => [...prev, ...files]);
    handleAttachmentsChange([...selectedFiles, ...files]); // Inform parent
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    handleAttachmentsChange(updatedFiles); // Inform parent
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const sanitizedContent = sanitizeMessage(value);

      if (sanitizedContent || selectedFiles.length) {
        handleSendMessage(sanitizedContent, selectedFiles);
        setValue("");
        setSelectedFiles([]);
        editorRef.current.getEditor().setContents([]);
      }
    }
  };

  const TruncateHTML = ({ html, maxLength = 150 }) => {
    const htmlContent = typeof html === "string" ? html : JSON.stringify(html);

    const truncateHtml = (htmlString, limit) => {
      const div = document.createElement("div");
      div.innerHTML = htmlString;
      let truncated = div.innerText.slice(0, limit);

      if (div.innerText.length > limit) {
        truncated += "...";
      }

      return truncated;
    };

    const truncatedText = truncateHtml(htmlContent, maxLength);

    return <span dangerouslySetInnerHTML={{ __html: truncatedText }} />;
  };

  const TruncateText = ({ text }) => {
    const maxLength = 10;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <ChatInputContainer>
      <ButtonsWrapper>
        {threadQuotedMessage !== null && (
          <div className="reply-message-div">
            <div className="reply-header">
              <div className="left">
                <span className="image-container">
                  <img src={threadQuotedMessage?.avatar} />
                </span>
                <span className="name">{threadQuotedMessage?.user_name}</span>
              </div>

              <span
                className="cross-icon"
                onClick={() => setThreadQuotedMessage(null)}
              >
                <RxCross2 size={20} />
              </span>
            </div>
            <span className="quoted-message">
              <span className="message">
                <TruncateHTML html={threadQuotedMessage?.message} />
              </span>
            </span>
          </div>
        )}

        <div className="main-div">
          <div className="text-area-wrapper">
            <ReactQuill
              ref={editorRef}
              name="message"
              className="quill-editor"
              theme="snow"
              value={value}
              onChange={handleEditorChange}
              placeholder="Type message here..."
              // onKeyDown={handleKeyDown}
              modules={{
                toolbar: {
                  container: "#quill-toolbar",
                  "emoji-toolbar": true,
                  "emoji-textarea": true,
                  "emoji-shortname": true,
                },
              }}
            />
          </div>
          <div id="quill-toolbar" className="custom-toolbar">
            {/* <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-link"></button> */}
          </div>

          <Button onClick={() => fileInputRef.current.click()}>
            <FiPaperclip />
            <input
              ref={fileInputRef}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Button>

          <Button
            onClick={() =>
              handleSendMessage(sanitizeMessage(value), selectedFiles)
            }
            className="send-button"
            disabled={sendingMessage}
            style={{
              backgroundColor: sendingMessage && "gray",
            }}
          >
            <MdSend color="white" size={20} />
          </Button>
        </div>

        <AttachmentList>
          {selectedFiles.map((file, index) => (
            <AttachmentItem key={index}>
              <span>
                <TruncateText text={file.name}></TruncateText>
              </span>
              <span className="file-size">
                ({(file.size / 1024).toFixed(1)}kb)
              </span>
              <RemoveIcon onClick={() => handleRemoveFile(index)}>
                <IoClose color="red" />
              </RemoveIcon>
            </AttachmentItem>
          ))}
        </AttachmentList>
      </ButtonsWrapper>
    </ChatInputContainer>
  );
};

export default ThreadInput;
