import styled from "styled-components";

const FiltersCoulumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;

  .exit-btn {
    display: flex;
    gap: 2px;
    align-items: center;
    cursor: pointer;
    span {
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
    }
  }

  .filter-by {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    .heading {
      font-size: 16px;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.49px;
    }

    .clear-btn {
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      cursor: pointer;
    }
  }

  .form-container {
    display: flex;
    max-width: 250px;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 363px;

    .form-main {
      display: flex;
      flex-direction: column;
      height: 66px;
      max-width: 250px;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        height: 134px;
        max-width: 250px;
        width: 100%;

        ${
          "" /* .form-items {
          margin-bottom: 0;
          height: 64px;
          max-width: 250px;
        } */
        }

        .datePicker {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          width: 250px;
          height: 41px;
          border-radius: 4px;
          box-shadow: none;
          border-color: unset;
          border: 1px solid #d0dee1;
          background: #fff;
          .ant-picker-clear {
            display: none;
          }
          .ant-picker-input > input::placeholder {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            width: 250px;
            height: 41px;
            border-radius: 4px;
            box-shadow: none;
            border-color: unset;
            background: #fff;
          }
        }

        .input {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          width: 250px;
          height: 41px;
          border-radius: 4px;
          box-shadow: none;
          border-color: unset;
          border: 1px solid #d0dee1;
          background: #fff;
        }

        .input::placeholder {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .input:focus::placeholder {
          color: transparent;
        }
        .input:focus {
          box-shadow: none;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input {
          border-color: unset;
          border: 1px solid #d0dee1;
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }
        .ant-form-item .ant-form-item-label > label::after {
          content: "";
        }
        .ant-form-item-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
          align-self: stretch;

          label {
            height: unset;
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            align-self: stretch;
          }
        }
        .location {
          align-items: center;
          min-width: 250px;
          height: 41px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          height: auto;
          min-height: 42px;
          padding-top: 4px;
          padding-right: 15px !important;

          .ant-select-selector {
            border: none;
            outline: none;
            box-shadow: none;
          }
          .location::placeholder {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .location:focus::placeholder {
            color: transparent;
          }
          .ant-select-selection-item {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }
  }
`;

export { FiltersCoulumnContainer };
