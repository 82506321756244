import React, { useEffect, useRef, useState } from "react";
import {
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
} from "./styles";
import { IoReturnUpForward, IoClose } from "react-icons/io5";
import { FiPaperclip } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Popconfirm, Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../../../App";
import { MdDragHandle } from "react-icons/md";
import { GoIssueClosed } from "react-icons/go";

Quill.register("modules/emoji", Emoji);

const { Option } = Select;

const MessageBody = ({
  onFileSelect,
  onInputChange,
  handleSend,
  sendingMail,
  email,
  handleDragStart,
  loading,
  isLiveChat,
  value,
  setValue,
  emailId,
  handleCloseConversation,
  handleOpenConversation,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [toRecipients, setToRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [fetchedEmails, setFetchedEmails] = useState([]);
  const { auth } = useSelector((state) => state);

  // Populate recipient options with email addresses
  const recipientOptions = email?.data?.sender_email
    ? [email?.data?.sender_email]
    : [];

  useEffect(() => {
    onInputChange("to", toRecipients);
  }, [toRecipients]);

  useEffect(() => {
    onInputChange("cc", ccRecipients);
  }, [ccRecipients]);

  useEffect(() => {
    onInputChange("bcc", bccRecipients);
  }, [bccRecipients]);

  useEffect(() => {
    if (email?.data?.sender_email) {
      setToRecipients([email?.data?.sender_email]);
    }
  }, [email?.data?.sender_email]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    onFileSelect(event.target.files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleEditorChange = (content) => {
    setValue(content);
    onInputChange("message", content);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleKeyPress = (event, type) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const inputValue = event.target.value.trim().toLowerCase(); // Normalize input

    if (event.key === "Enter" && emailRegex.test(inputValue)) {
      const addEmail = (list, setList) => {
        // Normalize existing emails for comparison
        const normalizedList = list.map((email) => email.trim().toLowerCase());

        if (normalizedList.includes(inputValue)) {
          console.error(
            "Duplicate email detected in the same field:",
            inputValue
          );
          return; // Prevent adding duplicate email
        }

        const newList = [...list, inputValue];
        console.log("Updated email list for type:", type, newList); // Debugging step
        setList(newList);
      };

      // Check which type of recipient to update
      if (type === "to") addEmail(toRecipients, setToRecipients);
      if (type === "cc") addEmail(ccRecipients, setCcRecipients);
      if (type === "bcc") addEmail(bccRecipients, setBccRecipients);

      // Clear the input field
      setTimeout(() => {
        event.target.value = "";
      }, 100);
    }
  };

  const extractEmails = (formattedRecipients) => {
    return formattedRecipients.map((recipient) => {
      const match = recipient.match(
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      );
      return match ? match[0] : recipient;
    });
  };

  const handleSelectChange = (value, type) => {
    const emailsOnly = extractEmails(value);

    const uniqueEmails = (emails) => [
      ...new Set(emails.map((email) => email.trim().toLowerCase())),
    ];

    switch (type) {
      case "to":
        setToRecipients(uniqueEmails(emailsOnly));
        break;
      case "cc":
        setCcRecipients(uniqueEmails(emailsOnly));
        break;
      case "bcc":
        setBccRecipients(uniqueEmails(emailsOnly));
        break;
      default:
        break;
    }
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const handleSearch = async (searchTerm) => {
    if (!searchTerm) return;

    const formData = new FormData();
    formData.append("search", searchTerm);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/suggestion_emails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.data?.status === "success") {
        setFetchedEmails(response.data.emails.filter(Boolean));
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const mergeEmails = (fetchedEmails) => {
    const mergedEmails = [
      ...new Set([email?.data?.sender_email, ...fetchedEmails]), // Ensure unique emails
    ].filter(Boolean);
    return mergedEmails;
  };

  return (
    <MessageBodyContainer>
      {/* To Recipients Field */}

      <div className="main-div">
        {emailId?.type === "email" && (
          <div className="left-div">
            <span className="back-icon">
              <IoReturnUpForward />
            </span>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Enter or choose recipients"
              value={toRecipients}
              onChange={(value) => handleSelectChange(value, "to")}
              onSearch={handleSearch}
              tokenSeparators={[",", ";", " "]}
            >
              {mergeEmails(fetchedEmails).map((recipient) => (
                <Option key={recipient} value={recipient}>
                  <TruncateText text={recipient} />
                </Option>
              ))}
            </Select>
          </div>
        )}
        {emailId?.type === "email" && (
          <div className="right-div">
            <span className="name" onClick={() => setShowCc(!showCc)}>
              Cc
            </span>
            <span className="name" onClick={() => setShowBcc(!showBcc)}>
              Bcc
            </span>
          </div>
        )}
      </div>

      {/* Cc Recipients Field */}
      {showCc && (
        <div className="main-div">
          <div className="left-div">
            <span className="name">Cc</span>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Enter or choose Cc recipients"
              value={ccRecipients}
              onChange={(value) => handleSelectChange(value, "cc")}
              onSearch={handleSearch} // Call handleSearch on every input change
              tokenSeparators={[",", ";", " "]}
            >
              {mergeEmails(fetchedEmails).map((recipient) => (
                <Option key={recipient} value={recipient}>
                  <TruncateText text={recipient} />
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {/* Bcc Recipients Field */}
      {showBcc && (
        <div className="main-div">
          <div className="left-div">
            <span className="name">Bcc</span>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Enter or choose Bcc recipients"
              value={bccRecipients}
              onChange={(value) => handleSelectChange(value, "bcc")}
              onSearch={handleSearch}
              tokenSeparators={[",", ";", " "]}
            >
              {mergeEmails(fetchedEmails).map((recipient) => (
                <Option key={recipient} value={recipient}>
                  <TruncateText text={recipient} />
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {/* Quill Editor */}
      {!loading && (
        <div className="text-area-wrapper">
          <ReactQuill
            name="message"
            className="quill-editor"
            theme="snow"
            value={value}
            onChange={handleEditorChange}
            placeholder="Type your message here..."
            modules={{
              toolbar: {
                container: `#quill-toolbar-${email?.data?.id}`, // Reference the unique toolbar ID
                "emoji-toolbar": true,
                "emoji-textarea": true,
                "emoji-shortname": true,
              },
            }}
          />
        </div>
      )}

      {/* Attachment List */}
      <AttachmentList>
        {selectedFiles.map((file, index) => (
          <AttachmentItem key={index}>
            <TruncateText text={file.name} />
            <span className="file-size">
              ({(file.size / 1024).toFixed(1)}kb)
            </span>
            <RemoveIcon onClick={() => handleRemoveFile(index)}>
              <IoClose />
            </RemoveIcon>
          </AttachmentItem>
        ))}
      </AttachmentList>

      {/* Buttons */}
      <ButtonsWrapper>
        <div id={`quill-toolbar-${email?.data?.id}`} className="custom-toolbar">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          {/* <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button> */}
          <button className="ql-link"></button>
        </div>

        <Button onClick={handleFileClick}>
          <FiPaperclip />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
        </Button>

        <Popconfirm
          title="Close Conversation"
          description={
            (emailId?.type === "chat" && email?.data?.is_closed === 1) ||
            (emailId?.type === "email" && email?.data?.status === 0)
              ? "Are you sure to Open?"
              : "Are you sure to close?"
          }
          onConfirm={() =>
            emailId?.type === "chat" && email?.data?.is_closed === 1
              ? handleOpenConversation()
              : emailId?.type === "email" && email?.data?.status === 1
              ? handleOpenConversation()
              : handleCloseConversation()
          }
          // onCancel={() => removeEmailFromList(emailId)}
          okText="Yes"
          cancelText="No"
        >
          <button className="send-button" style={{ cursor: "pointer" }}>
            {emailId?.type === "chat" && email?.data?.is_closed === 1
              ? "Reopen"
              : emailId?.type === "email" && email?.data?.status === 1
              ? "Reopen"
              : "Close"}
          </button>
        </Popconfirm>

        <button
          onClick={handleSend}
          className="send-button"
          disabled={sendingMail || value === ""}
        >
          {sendingMail ? "Sending" : "Send"}
        </button>
      </ButtonsWrapper>
    </MessageBodyContainer>
  );
};

export default MessageBody;
