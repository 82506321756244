import React, { useState, useEffect, useRef } from "react";
import { Container, ContentContainer, DrawerContainer } from "./styles";
import UserView from "./companyDetailComponents/userView/UserView";
import Account from "./companyDetailComponents/account/Account";
import Mailbox from "./companyDetailComponents/mailbox/Mailbox";
import Payment from "./companyDetailComponents/payment/Payment";
import Invoices from "./companyDetailComponents/invoices/Invoices";
import Activity from "./companyDetailComponents/activity/Activity";
import Support from "./companyDetailComponents/support/Support";
import PhoneNumberlist from "./companyDetailComponents/phone/PhoneNumberlist";
import LiveAnswering from "./companyDetailComponents/liveAnswering/LiveAnswering";
import { InstanceWithAuth } from "../../../App";
import Spinner from "../../../common/Spinner";
import { useSelector } from "react-redux";
import { Form } from "antd";
import PlansAndProducts from "./companyDetailComponents/plansAndProducts/PlansAndProducts";
import SupportHeader from "./companyDetailComponents/supportHeader/SupportHeaderr";
import Ticket from "./companyDetailComponents/ticket/Ticket";
import _ from "lodash";
import useGetData from "../../../hooks/useGetData";
import { useParams } from "react-router-dom";
import { useGlobalAlertMessage } from "../../../hooks/useAlertMessage";
import UserMeetingListing from "../../userList/userDetailSidebar/userDetailComponents/userMeetingListing/UserMeetingListing";
const CompanyDetailSidebar = ({
  userData,
  open,
  setOpen,
  userID,
  fetchAllContacts,
  fetchUserDetails,
  setSideBarOpen,
  hanleSearchContact,
  canHide,
  supportHeader,
  email,
  contactData,
  additionalTabs,
  selectedEmailId,
  buttonSize = "90px",
  showingInDrawer,
}) => {
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [enableSaveBtn, setEnableBtn] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { ticketID } = useParams();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [dashboardEmail, setDashboardEmailEmail] = useState();
  const [initialValues, setInitialValues] = useState({});
  const { auth, app } = useSelector((state) => state);
  const { showMessage } = useGlobalAlertMessage();
  const [form] = Form.useForm();
  const {
    data: companyData,
    loading: contactDataLoading,
    error: contactDataErro,
    refetchData: refetchConatctData,
  } = useGetData(`companies/${userID}`);
  const { data: companyUsersData } = useGetData(
    `companies/${userID}/users?per_page=100`
  );
  const currentDashBoardLofigEmial = useRef(null);
  const CompnayAdmin = companyUsersData?.data?.find(
    (user) => user?.user_type == "company_admin"
  );
  useEffect(() => {
    form.setFieldsValue({
      CompnayName: companyData?.data?.name,
      DashboardLoginEmail: companyData?.data?.dashboard_login_email || "",
      loginEmail: CompnayAdmin?.email || "",
      emailNotificationCheck:
        companyData?.data?.general_notifications &&
        companyData?.data?.general_notifications !== ""
          ? JSON.parse(companyData?.data?.general_notifications)
          : false,
      emailForNotification:
        companyData?.data?.email_for_general_notifications || "",

      emailForCallNotificationCheck:
        companyData?.data?.live_answering_email_notifications &&
        companyData?.data?.live_answering_email_notifications !== ""
          ? JSON.parse(companyData?.data?.live_answering_email_notifications)
          : false,
      emailForCallNotification:
        companyData?.data?.email_for_live_answering_email_notifications || "",

      mailboxNotificationCheck:
        companyData?.data?.mailbox_email_notifications &&
        companyData?.data?.mailbox_email_notifications !== ""
          ? JSON.parse(companyData?.data?.mailbox_email_notifications)
          : false,
      emailForMailBoxNotification:
        companyData?.data?.email_for_mailbox_email_notifications || "",
    });
    const initialData = {
      CompnayName: companyData?.data?.name,
      emailNotificationCheck:
        companyData?.data?.general_notifications &&
        companyData?.data?.general_notifications !== ""
          ? JSON.parse(companyData?.data?.general_notifications)
          : false,
      emailForNotification:
        companyData?.data?.email_for_general_notifications || "",

      emailForCallNotificationCheck:
        companyData?.data?.live_answering_email_notifications &&
        companyData?.data?.live_answering_email_notifications !== ""
          ? JSON.parse(companyData?.data?.live_answering_email_notifications)
          : false,
      emailForCallNotification:
        companyData?.data?.email_for_live_answering_email_notifications || "",

      mailboxNotificationCheck:
        companyData?.data?.mailbox_email_notifications &&
        companyData?.data?.mailbox_email_notifications !== ""
          ? JSON.parse(companyData?.data?.mailbox_email_notifications)
          : false,
      emailForMailBoxNotification:
        companyData?.data?.email_for_mailbox_email_notifications || "",
    };
    setInitialValues(initialData);
  }, [companyData]);
  const handleFormItemChange = (changedValues, allValues) => {
    const keys = Object.keys(changedValues);
    const fieldsToIgnore = ["password", "DashboardLoginEmail"];
    const isIgnoredFieldChanged = keys.every((key) =>
      fieldsToIgnore.includes(key)
    );
    if (isIgnoredFieldChanged) {
      setEnableBtn(false);
      setShowSaveBtn(false);
    } else {
      const filteredInitialValues = _.omit(initialValues, fieldsToIgnore);
      const filteredAllValues = _.omit(allValues, fieldsToIgnore);
      const isChanged = !_.isEqual(filteredInitialValues, filteredAllValues);

      setEnableBtn(isChanged);
      setShowSaveBtn(isChanged);
    }
  };
  const onCancel = () => {
    setShowSaveBtn(false);
    form.setFieldsValue({
      CompnayName: companyData?.data?.name,
      DashboardLoginEmail: companyData?.data?.dashboard_login_email || "",
      loginEmail: CompnayAdmin?.email || "",

      emailNotificationCheck:
        companyData?.data?.general_notifications &&
        companyData?.data?.general_notifications !== ""
          ? JSON.parse(companyData?.data?.general_notifications)
          : false,
      emailForNotification:
        companyData?.data?.email_for_general_notifications || "",

      emailForCallNotificationCheck:
        companyData?.data?.live_answering_email_notifications &&
        companyData?.data?.live_answering_email_notifications !== ""
          ? JSON.parse(companyData?.data?.live_answering_email_notifications)
          : false,
      emailForCallNotification:
        companyData?.data?.email_for_live_answering_email_notifications || "",

      mailboxNotificationCheck:
        companyData?.data?.mailbox_email_notifications &&
        companyData?.data?.mailbox_email_notifications !== ""
          ? JSON.parse(companyData?.data?.mailbox_email_notifications)
          : false,
      emailForMailBoxNotification:
        companyData?.data?.email_for_mailbox_email_notifications || "",
    });
  };
  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let formData = new FormData();
        formData.append("name", values?.CompnayName ? values?.CompnayName : "");
        formData.append(
          "dashboard_login_email",
          values?.DashboardLoginEmail ? values?.DashboardLoginEmail : ""
        );
        formData.append(
          "mailbox_email_notifications",
          values?.mailboxNotificationCheck == undefined
            ? false
            : values?.mailboxNotificationCheck
        );
        formData.append(
          "email_for_mailbox_email_notifications",
          values.emailForMailBoxNotification
            ? values.emailForMailBoxNotification
            : ""
        );
        formData.append(
          "live_answering_email_notifications",
          values.emailForCallNotificationCheck == undefined
            ? false
            : values.emailForCallNotificationCheck
        );
        formData.append(
          "email_for_live_answering_email_notifications",
          values.emailForCallNotification
            ? values?.emailForCallNotification
            : ""
        );
        formData.append(
          "general_notifications",
          values.emailNotificationCheck == undefined
            ? false
            : values?.emailNotificationCheck
        );
        formData.append(
          "email_for_general_notifications",
          values?.emailForNotification ? values?.emailForNotification : ""
        );

        handleAddCompany(formData);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleAddCompany = async (formData) => {
    try {
      setIsLoading(true);
      const response = await InstanceWithAuth.post(
        `companies/${companyData?.data?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false);
        refetchConatctData();
        fetchAllContacts("");
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error msgs");
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  return (
    <DrawerContainer open={open}>
      <Container>
        {showingInDrawer ? null : (
          <>
            {supportHeader ? (
              <SupportHeader
                contactData={contactData}
                userID={userID}
                email={email}
              />
            ) : ticketID ? null : (
              <UserView
                setOpen={setOpen}
                userID={userID}
                fetchUserDetails={fetchUserDetails}
                setSideBarOpen={setSideBarOpen}
                hanleSearchContact={hanleSearchContact}
                canHide={canHide}
              />
            )}
          </>
        )}

        <ContentContainer>
          <Account
            userID={userID}
            setPrimaryEmail={setPrimaryEmail}
            setDashboardEmailEmail={setDashboardEmailEmail}
            setShowSaveBtn={setShowSaveBtn}
            fetchUserDetails={fetchUserDetails}
            formUpdatingloading={loading}
            setInitialValues={setInitialValues}
            handleFormItemChange={handleFormItemChange}
            companyData={companyData}
            CompnayAdmin={CompnayAdmin}
            form={form}
            auth={auth}
            contactDataLoading={contactDataLoading}
            currentDashBoardLofigEmial={currentDashBoardLofigEmial}
          />
          {additionalTabs && (
            <Ticket userID={userID} selectedEmailId={selectedEmailId} />
          )}
          <Mailbox userID={userID} companyData={companyData} />
          <PlansAndProducts
            userID={userID}
            companyData={companyData}
            buttonSize={buttonSize}
          />
          <PhoneNumberlist userID={userID} companyData={companyData} />
          <LiveAnswering userID={userID} companyData={companyData} />
          <Payment userId={userID} userData={userData} />
          <Invoices userID={userID} />
          <UserMeetingListing
            isCompany={true}
            userID={userID}
            userData={companyData}
          />
          <Activity userID={userID} />
          <Support userID={userID} app={app} />
          {showSaveBtn && (
            <div
              className="save-btn"
              style={{
                position: "fixed",
              }}
            >
              <button className="btn cancel-btn" onClick={onCancel}>
                cancel
              </button>
              <button
                onClick={handleExternalSubmit}
                disabled={!enableSaveBtn ? true : false}
                className={
                  enableSaveBtn ? "save-btn btn" : "btn save-btn-disabled"
                }
              >
                {loading ? <Spinner></Spinner> : "Save"}
              </button>
            </div>
          )}
        </ContentContainer>
      </Container>
    </DrawerContainer>
  );
};

export default CompanyDetailSidebar;
