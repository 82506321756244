import React, { useState } from "react";
import { ProductCardWrapper } from "./styles";
import DataNotFound from "../../../../../../common/DataNotFound";
import GetLogo from "../../../../../getlogo/getlogo";
import { Progress } from "antd";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import StatusLabelButton from "../../../../../statusLabelButton/StatusLabelButton";
function MeetingRoomAccess({ productsData, companyData }) {
  const [isHovered, setIsHovered] = useState(false);
  const [panel1Collapsed, setPanel1Collapsed] = useState(true);

  const mailbox = "";
  const productData = "";

  const handlePanel1ButtonClick = () => {
    setPanel1Collapsed(!panel1Collapsed);
  };

  return (
    <ProductCardWrapper>
      <div className="detail-warpper">
        <div className="title-div" onClick={handlePanel1ButtonClick}>
          <div className="title-progress-wrapper">
            <span className="forward">
              {panel1Collapsed ? (
                <MdKeyboardArrowUp />
              ) : (
                <MdKeyboardArrowDown />
              )}
            </span>
            <div className="title-wrapper">
              <div className="make-column">
                <div className="title">Meeting Room Access</div>
                {/* {productsData?.meeting_room_credits_left && ( */}
                <div className="meetings-left">
                  <span className="storage">{`${productsData?.meeting_room_credits_left} credits left`}</span>
                </div>
                {/* )} */}
              </div>
              <StatusLabelButton
                Data={productsData}
                width="90px"
              ></StatusLabelButton>
            </div>
            {/* <div className="progress-wrapper">
              <span className="storage">{productsData?.number}</span>
              <Progress
                className="storage-progress"
                percent={
                  (productsData?.storage_used / productsData?.total_storage) *
                  100
                }
                size="small"
                showInfo={false}
                style={{ width: "183px", height: "12px", visibility: "hidden" }}
              />
            </div> */}
          </div>
          {/* <button className="view-btn">View</button> */}
        </div>
      </div>
      {!panel1Collapsed &&
        (productsData?.data?.length === 0 ? (
          <DataNotFound label="No Subscribed Addons!" />
        ) : (
          <div className="item-div">
            {productsData?.data?.map((item) => (
              <div
                className="item-wrapper"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div className="item">
                  <div className="add-title">{item?.addon_name}</div>
                  <div className="add-value">{item?.quantity}</div>
                  <div className="add-amount">+ ${item?.total}</div>
                  <div className="edit-view">
                    {/* <span className="edited-date">
                  Last Edits {item?.updated_at}
                </span> */}
                    <button className="view-btn">View</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </ProductCardWrapper>
  );
}

export default MeetingRoomAccess;
