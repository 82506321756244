import React, { useState, useEffect } from "react";
import ChatHeaderContainer from "./styles";
import ChatHeaderDropDown from "../chatHeaderDropDown/ChatHeaderDropDown";
import Avatar from "./avatar/avatar";
import { InstanceWithAuth } from "../../../../App";
import { IoMdContract } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Echo from "laravel-echo";
import { handleUpdateChatOwner } from "../../../../redux/app/appSlice";
import {
  handleAddCurrentActiveChatThread,
  handleAddCurrentActiveChatTab,
  handleShowContractedChat,
} from "../../../../redux/app/appSlice";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

const ChatHeader = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  currentEmployeeImage,
  email,
  selectedFolder,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const { auth } = useSelector((state) => state);
  const [joinedChats, setJoinedChats] = useState([]);
  const [joinigChat, setJoinigChat] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const addEmailIdToLocalStorage = (emailId) => {
    const savedEmailIds =
      JSON.parse(localStorage.getItem("emailIdsArray")) || [];

    let type;
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      type = "email";
    } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
      type = "chat";
    }

    const isAlreadySaved = savedEmailIds.some(
      (item) => item.id === emailId && item.type === type
    );

    if (!isAlreadySaved) {
      savedEmailIds.push({ id: emailId, type });

      localStorage.setItem("emailIdsArray", JSON.stringify(savedEmailIds));

      const event = new Event("localStorageChange");
      window.dispatchEvent(event);
    }
  };

  const addActiveChatToContractedChat = (chatDetials) => {
    dispatch(handleAddCurrentActiveChatThread(chatDetials));
    dispatch(handleAddCurrentActiveChatTab(selectedFolder));
    dispatch(handleShowContractedChat(true));
    const event = new Event("localStorageChange");
    window.dispatchEvent(event);
  };

  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: "local",
      cluster: "your-app-cluster",
      wsHost: `${process.env.REACT_APP_SOCKET_BASE_URL}`,
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
      disableStats: true,
    });
    echo.channel("chat.joined").listen("ChatJoined", (event) => {
      console.log("New event received:", event);
      setJoinedChats((prevsJoinedChats) => [...prevsJoinedChats, event]);
    });
    echo.connector.pusher.connection.bind("connected", () => {
      console.log("Successfully connected to Pusher in chat listing");
    });
    return () => {
      echo.disconnect();
    };
  }, []);

  const handleJoinChat = async (chatId) => {
    setSelectedUser(auth?.userObj);
    setCurrentEmployeeImage(auth?.userObj);
    setJoinigChat(true);
    try {
      const response = await InstanceWithAuth.get(
        `live-chat/join-chat/${chatId}`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        dispatch(
          handleUpdateChatOwner({
            owner: `${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`,
          })
        );
        setJoinigChat(false);
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setJoinigChat(false);

      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  return (
    <ChatHeaderContainer>
      <div className="wrapper">
        <span className="title">Owner</span>
        <div className="avatar-name-container">
          <Avatar
            currentEmployeeImage={email?.data?.user_id && currentEmployeeImage}
          />
          {/* 
          {(selectedFolder == 'All Open' &&
            app?.allOpenConversationDetails?.Model == 'LiveChat') ||
          selectedFolder == 'Chat' ? (
            <div className="title owner-name">
              {app?.allOpenConversationDetails?.owner}
            </div>
          ) : ( */}
          <ChatHeaderDropDown
            selectedEmailId={selectedEmailId}
            setCurrentEmployeeImage={setCurrentEmployeeImage}
            email={email}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          ></ChatHeaderDropDown>
          {/* )} */}
        </div>
      </div>

      <div style={{ display: "flex", gap: "5px" }}>
        {app?.allOpenConversationDetails &&
        app?.allOpenConversationDetails?.owner == null &&
        app?.allOpenConversationDetails?.Model == "LiveChat" &&
        !joinedChats?.some(
          (obj) => obj?.liveChat?.id == app?.allOpenConversationDetails?.id
        ) ? (
          <button
            onClick={() => handleJoinChat(app?.allOpenConversationDetails?.id)}
            className="join-btn"
          >
            {joinigChat ? "Joining Chat" : "Join Chat"}
          </button>
        ) : null}
        <button onClick={() => addEmailIdToLocalStorage(selectedEmailId)}>
          <IoMdContract size={18} />
        </button>
      </div>
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
