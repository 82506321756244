import React, { useState } from "react";
import {
  AllTabsContainer,
  TabContainer,
  TabHeader,
  TabContent,
  ArrowIcon,
} from "./styles";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import AboutThisTicket from "./aboutThisTicket/AboutThisTicket";
import { LuExternalLink } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import useGetData from "../../../../hooks/useGetData";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

const AllTabs = ({
  ticket,
  refetchData,
  CallingFromTicketCenter,
  refetchTikcedBoardData,
  setOpenTicketDrawer,
  callingFromTicketDetails,
}) => {
  const [activeTabs, setActiveTabs] = useState([0]);
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const { data: customFieldsData, error: customFieldsError } =
    useGetData(`ticket-custom-fields`);
  const { auth, app } = useSelector((state) => state);
  const { showMessage } = useGlobalAlertMessage();
  const [isModified, setIsModified] = useState(false);

  const toggleTab = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    const values = form.getFieldsValue();
    const data = {
      contact_id: ticket?.contact_id || "",
      company_id: ticket?.company_id || "",
      user_id: values.associate_employee || "",
      name: values.ticketName || "",
      description: values.description || "",
      priority: values.priority || "",
    };

    customFieldsData?.data?.forEach((customField) => {
      const fieldName = customField?.name;
      data[fieldName] = values[fieldName];
    });

    const encodedData = new URLSearchParams(data).toString();

    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${ticket?.id}`,
        encodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        CallingFromTicketCenter && setOpenTicketDrawer(false);

        setIsSaving(false);
        refetchData(ticket?.id);
        showMessage({
          type: "success",
          content: response?.data?.message,
        });

        if (!callingFromTicketDetails) {
          refetchTikcedBoardData();
          // handleCancel();
        }
      }
    } catch (error) {
      setIsSaving(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  return (
    <AllTabsContainer>
      {tabs.map((tab, index) => (
        <TabContainer key={index}>
          <TabHeader
            onClick={() => toggleTab(index)}
            isActive={activeTabs.includes(index)}
          >
            <ArrowIcon isActive={activeTabs.includes(index)}>
              {activeTabs.includes(index) ? (
                <FiChevronDown />
              ) : (
                <FiChevronRight />
              )}
            </ArrowIcon>
            {tab.title}
          </TabHeader>
          {activeTabs.includes(index) && (
            <TabContent>
              <AboutThisTicket
                ticket={ticket}
                refetchData={refetchData}
                refetchTikcedBoardData={refetchTikcedBoardData}
                setOpenTicketDrawer={setOpenTicketDrawer}
                CallingFromTicketCenter={CallingFromTicketCenter}
                callingFromTicketDetails={callingFromTicketDetails}
                setIsModified={setIsModified}
                form={form}
              />
            </TabContent>
          )}
          {/*  */}

          {isModified && (
            <div
              style={{
                left: callingFromTicketDetails && 0,
              }}
              className="button-div"
            >
              <button
                style={{
                  background: !isModified && "gray",
                }}
                type="button"
                onClick={handleSubmit}
                disabled={isSaving || !isModified}
                className={isSaving ? "saving" : ""}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
              <button
                className="close-button"
                type="button"
                onClick={() => {
                  form.resetFields();
                  setIsModified(false);
                  // if (!callingFromTicketDetails) {
                  //   setOpenTicketDrawer(false);
                  // }
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </TabContainer>
      ))}
    </AllTabsContainer>
  );
};

const tabs = [
  {
    title: "About this ticket",
    component: <div>Dunning and recovery component content...</div>,
  },
];

export default AllTabs;
