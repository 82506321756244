import styled from 'styled-components'
const FormConatiner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  padding-bottom: 32px;

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 548px; */
    max-width: 100%;
    gap: 20px;
    align-self: stretch;
    height: auto;
    .error-msg {
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: #ff4d4f;
    }
    .form-title {
      color: var(--UD-Dark-100, #212c3b);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      .scan-checkbox {
        display: flex;
        gap: 5px;
      }
    }
    .ant-form-item-explain-error {
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .ant-select-dropdown.custom-dropdown {
      width: 359px !important;
    }
    .dropdown-select .ant-select-selection-placeholder {
      color: #d9d9d9; /* Ensure placeholder color is not the same as background */
    }
    .custom-select .ant-select-selection-item,
    .custom-select .ant-select-selection-placeholder {
      line-height: 2;
    }
    .custom-select .ant-select-selection-search {
      /* inset-inline-start: 12px !important; */
      top: -5px;
      input {
        height: auto;
        padding: 0;
        margin: 0;
      }
    }
    .input-half-number {
      display: flex !important;
      width: 175px !important;
      display: flex;
      .dropdown-select {
        width: 120px !important;
      }
      .dropdown-select .ant-select-selection-placeholder {
        color: #d9d9d9; /* Ensure placeholder color is not the same as background */
      }
    }
    .custom-select .ant-select-item {
      padding: 10px;
    }
    .select-dropdown {
      width: 100%;
      justify-content: space-between;
    }
    .title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
    .ant-form-item-label {
      padding: 0 0 0px;
    }

    .ant-form-item {
      height: 61px;
      margin-bottom: unset;
    }

    .ant-form-item-row {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 1px;

      align-self: stretch;
      margin-bottom: 0;

      label {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }

      .custom-select .ant-select-arrow {
        display: none !important;
      }
      .text-area {
        resize: none !important;
      }
      .text-area {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 359px;
        max-width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 79px;
        font-family: 'Outfit';
        .ant-select,
        .ant-select-selector {
          border: none;
          padding: 0;
        }
        .ant-select,
        .ant-select-selector:hover:active::after {
          border: none;
          padding: 0;
        }
      }
      .ant-select-selection-item {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .username-input {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 359px;
        max-width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 41px;
        font-size: 14px;
        /* color: var(--Gray-50, rgba(85, 99, 115, 0.5)); */
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        .ant-select,
        .ant-select-selector {
          border: none;
          padding: 0;
        }
        .ant-select,
        .ant-select-selector:hover:active::after {
          border: none;
          padding: 0;
        }
      }
      .input-static {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 359px;
        max-width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        background: #f9f9f9;
        height: 41px;
        .ant-select,
        .ant-select-selector {
          border: none;
          padding: 0;
        }
        .ant-select,
        .ant-select-selector:hover:active::after {
          border: none;
          padding: 0;
        }
      }
      .input-half {
        width: 150px !important;
        max-width: 100%;
      }
      .dropdown-select {
        border-right: 0;
        border-radius: 4px 0 0 4px;
      }
      .input-number {
        border-left: 0;
        border-radius: 0 4px 4px 0;
      }
      .input-half-number {
        display: flex !important;
        width: 177px !important;
        display: flex;
        .dropdown-select {
          width: 120px !important;
        }
      }

      .two-inputs {
        width: 175px !important;
        max-width: 100%;
      }
      .two-inputs-quantity {
        /* width: 177px !important; */
        width: 175px;
        max-width: 100%;
      }
      .custom-message {
        height: 79px;
      }
      .username-input::placeholder {
        color: var(--Gray-40, rgba(85, 99, 115, 0.4));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }

      .username-input:focus::placeholder {
        color: transparent;
      }
      .username-input:focus {
        box-shadow: none;
      }

      label::after {
        content: '';
      }
    }
    .select-input {
      display: flex;
        justify-content: space-between;
        width: 359px;
        max-width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 41px;
        font-size: 14px;
        /* color: var(--Gray-50, rgba(85, 99, 115, 0.5)); */
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      .ant-select-selection-search {
        /* inset-inline-start: 12px !important; */
      }
      .ant-select,
      .ant-select-selection-search
      .ant-select-selector {
        border: none;
        padding: 12px;
        width: 100%;
      }
      .ant-select,
      .ant-select-selector:hover:active::after {
        border: none;
        padding: 0;
      }
    }
  }
`
export { FormConatiner }
