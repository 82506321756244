import styled from "styled-components";

const AllTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e3eced;

  .button-div {
  position: absolute;
  z-index: 2000;
  bottom: 0;
  right: 0;
  width: 358px;
  height: 30px;
  align-items: center;
  flex-direction: row-reverse;
    // border-radius: 8px;
    background-color: var(--Blue-100, #fbfafb);
    border: 1px solid #ececec;
  padding: 20px;

  background: white;
      display: flex;
      justify-content: end;
      margin-top: 9px;
      gap: 15px;
      
      .close-button {
        display: flex;
        height: 32px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        outline: none;
        border-radius: 3px;
        border: 1px solid #d0dee1;
        background: #fff;
        cursor: pointer;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
      button {
        display: flex;
        height: 32px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        gap: 8px;
        border-radius: 3px;
        background: var(--Blue-100, #4cbbcb);
        cursor: pointer;
        color: var(--White-100, #fff);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  font-size: 14px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.49px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? "#4cbbcb" : "#ffffff")};
  color: ${(props) =>
    props.isActive ? "#ffffff" : "var(--Dark-Gray-100, #2b3746)"};

  &:hover {
    background-color: ${(props) => (props.isActive ? "#4cbbcb" : "#f0f0f0")};
  }
`;

const TabContent = styled.div`
  padding: 10px;
  font-size: 14px;
`;

const ArrowIcon = styled.span`
  margin-right: 10px;
  margin-top: 5px;
  font-size: 16px;
  color: ${(props) => (props.isActive ? "#ffffff" : "#4cbbcb")};
`;

export { AllTabsContainer, TabContainer, TabHeader, TabContent, ArrowIcon };
