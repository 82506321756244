import React, { useState } from "react";
import { MainContainer } from "./styles";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import PageHeader from "../../components/adminSettings/pageHeader/PageHeader";
import SettingsSidebar from "../../components/adminSettings/settingsSidebar/SettingsSidebar";
import GeneralSettingsPage from "../../components/adminSettings/generalSettingsPage/GeneralSettingsPage";
import TicketManagemnet from "../../components/adminSettings/ticketManagemnet/TicketManagemnet";
import InvoicingSettings from "../../components/adminSettings/invoicingSettings/InvoicingSettings";
import { useSelector } from "react-redux";
import LiveAnsweringSettings from "../../components/adminSettings/liveAnsweringSettings/LiveAnsweringSetting";
import MailboxSettings from "../../components/adminSettings/mailboxSettings/MailboxSettings";
import MeetingRoomSettings from "../../components/adminSettings/meetingRoomSettings/MeetingRoomSettings";

const AdminSettings = () => {
  const [activeTab, setActiveTab] = useState("General Settings");
  const { app, auth } = useSelector((state) => state);
  // test
  const renderContent = () => {
    switch (activeTab) {
      case "General Settings":
        return <GeneralSettingsPage />;
      case "Mailbox Settings":
        return <MailboxSettings />;
      case "Live Answering":
        return <LiveAnsweringSettings />;
      case "Meeting Room Access":
        return <MeetingRoomSettings />;
      case "Ticket Management":
        return <TicketManagemnet />;
      case "Invoicing":
        return <InvoicingSettings />;
      default:
        return <GeneralSettingsPage />;
    }
  };

  return (
    <MainContainer isSidebarCollapsed={app?.sidebarCollapsed}>
      <CommonHaeder firstBcItem={"Setting"} firstBcIcon={"setting"} />
      <div className="page-header">
        <PageHeader />
      </div>

      <div className="main-page">
        <div className="sidebar">
          <SettingsSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>

        <div className="body">{renderContent()}</div>
      </div>
    </MainContainer>
  );
};

export default AdminSettings;
