import React, { useEffect, useRef, useState } from "react";
import {
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
} from "./styles";
import { IoReturnUpForward, IoClose } from "react-icons/io5";
import { FiPaperclip } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Input, Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { InstanceWithAuth } from "../../../../../App";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import moment from "moment";
import { useGlobalAlertMessage } from "../../../../../hooks/useAlertMessage";

Quill.register("modules/emoji", Emoji);

const { Option } = Select;

const MessageBody = ({
  onFileSelect,
  onInputChange,
  handleSend,
  sendingMail,
  email,
  selectedFolder,
  forwardMailDetails,
  isForwardMail,
  handleReplyEmail,
  clearInputtext,
  forwardFiles,
  setForwardFiles,
  sendChatToEmail,
  setSendChatToEmail,
  chatThread,
  selectedTab,
  setSelectedTab,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [value, setValue] = useState("");
  const [toRecipients, setToRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [recipientsForHistory, setRecipientsForHistory] = useState([]);
  const [ccForHistory, setCCForHistory] = useState([]);
  const [bccForHistory, setBCCForHistory] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [showEmailCc, setShowEmailCc] = useState(false);
  const [showEmailBcc, setShowEmailBcc] = useState(false);
  const [fetchedEmails, setFetchedEmails] = useState([]);
  const { auth, app } = useSelector((state) => state);
  const [searchValue, setSearchValue] = useState("");
  const [subject, setSubject] = useState("Chat Transcript");

  const [sendingEmail, setSendingEmail] = useState(false);
  const [message, setMessage] = useState(null);

  // Populate recipient options with email addresses
  const recipientOptions = email?.data?.sender_email
    ? [email?.data?.sender_email]
    : [];

  useEffect(() => {
    onInputChange("to", toRecipients);
  }, [toRecipients]);

  useEffect(() => {
    onInputChange("cc", ccRecipients);
  }, [ccRecipients]);

  useEffect(() => {
    onInputChange("bcc", bccRecipients);
  }, [bccRecipients]);

  useEffect(() => {
    if (isForwardMail) {
      setToRecipients([]);
    } else if (email?.data && isForwardMail == false) {
      const emailToSet =
        email?.data?.is_sent === 0
          ? email?.data?.sender_email
          : email?.data?.receiver_email;
      setToRecipients([emailToSet]);
    }
  }, [email?.data?.sender_email, isForwardMail]);

  useEffect(() => {
    if (app?.allOpenConversationDetails?.Model == "LiveChat") {
      setRecipientsForHistory([
        app?.allOpenConversationDetails?.company_id
          ? app?.allOpenConversationDetails?.company?.dashboard_login_email
          : app?.allOpenConversationDetails?.contact_id
          ? app?.allOpenConversationDetails?.contact?.email
          : app?.allOpenConversationDetails?.guest?.email,
      ]);
    }
  }, [app?.allOpenConversationDetails]);

  useEffect(() => {
    {
      forwardMailDetails &&
        setForwardFiles([...forwardMailDetails?.content?.attachments]);
    }
    if (forwardMailDetails == "") {
      setForwardFiles([]);
    }
  }, [forwardMailDetails?.content?.attachments, isForwardMail]);

  useEffect(() => {
    if (isForwardMail) {
      const originalMessage = forwardMailDetails?.content?.email_body || "";

      // Format the `ccRecipients` as a comma-separated string
      const ccList = ccRecipients.length > 0 ? ccRecipients.join(", ") : "";

      // Define the forwarded header with HTML formatting
      const forwardedHeader = `
      <p>---------- Forwarded message ---------</p>
      <p>From: ${forwardMailDetails?.content?.sender_name} &lt;${
        forwardMailDetails?.content?.sender_email
      }&gt;</p>
      <p>Date: ${moment(forwardMailDetails?.content?.created_at).format(
        "ddd, MMM D, YYYY h:mm A"
      )}</p>
      <p>Subject: ${forwardMailDetails?.content?.subject}</p>
      ${ccList ? `<p>CC: ${ccList}</p>` : ""}  <!-- Include CC if available -->
      <br>
    `;

      // Combine the forwarded header with the original message
      setValue(`${forwardedHeader}${originalMessage}`);
      onInputChange("message", `${forwardedHeader}${originalMessage}`);
    } else {
      setValue("");
    }
  }, [isForwardMail, ccRecipients, forwardMailDetails]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    onFileSelect(event.target.files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };
  const handleForwardedFile = (index) => {
    const updatedFiles = forwardFiles.filter((_, i) => i !== index);
    setForwardFiles(updatedFiles);
  };

  const handleEditorChange = (content) => {
    setValue(content);
    onInputChange("message", content);
    setMessage(content);
  };

  useEffect(() => {
    setValue("");
  }, [clearInputtext]);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  // const handleKeyPress = (event, type) => {
  //   const emailRegex = /\S+@\S+\.\S+/;
  //   const inputValue = event.target.value.trim().toLowerCase(); // Normalize input

  //   if (event.key === "Enter" && emailRegex.test(inputValue)) {
  //     const addEmail = (list, setList) => {
  //       // Normalize existing emails for comparison
  //       const normalizedList = list.map((email) => email.trim().toLowerCase());

  //       if (normalizedList.includes(inputValue)) {
  //         console.error(
  //           "Duplicate email detected in the same field:",
  //           inputValue
  //         );
  //         return; // Prevent adding duplicate email
  //       }

  //       const newList = [...list, inputValue];
  //       console.log("Updated email list for type:", type, newList); // Debugging step
  //       setList(newList);
  //     };

  //     // Check which type of recipient to update
  //     if (type === "to") addEmail(toRecipients, setToRecipients);
  //     if (type === "cc") addEmail(ccRecipients, setCcRecipients);
  //     if (type === "bcc") addEmail(bccRecipients, setBccRecipients);
  //     if (type === "history-email")
  //       addEmail(recipientsForHistory, setRecipientsForHistory);
  //     if (type === "history-cc-email") addEmail(ccForHistory, setCCForHistory);
  //     if (type === "history-bcc-email")
  //       addEmail(bccForHistory, setBCCForHistory);

  //     // Clear the input field
  //     setSearchValue("");
  //     setTimeout(() => {
  //       event.target.value = "";
  //     }, 100);
  //   }
  // };

  const handleKeyPress = (event, type) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const inputValue = event.target.value.trim().toLowerCase(); // Normalize input

    // Handle "Enter" key for email addition
    if (event.key === "Enter" && emailRegex.test(inputValue)) {
      const addEmail = (list, setList) => {
        // Normalize existing emails for comparison
        const normalizedList = list.map((email) => email.trim().toLowerCase());

        if (normalizedList.includes(inputValue)) {
          console.error(
            "Duplicate email detected in the same field:",
            inputValue
          );
          return; // Prevent adding duplicate email
        }

        const newList = [...list, inputValue];
        console.log("Updated email list for type:", type, newList); // Debugging step
        setList(newList);
      };

      // Check which type of recipient to update
      if (type === "to") addEmail(toRecipients, setToRecipients);
      if (type === "cc") addEmail(ccRecipients, setCcRecipients);
      if (type === "bcc") addEmail(bccRecipients, setBccRecipients);
      if (type === "history-email")
        addEmail(recipientsForHistory, setRecipientsForHistory);
      if (type === "history-cc-email") addEmail(ccForHistory, setCCForHistory);
      if (type === "history-bcc-email")
        addEmail(bccForHistory, setBCCForHistory);

      // Clear the input field
      setSearchValue("");
      setTimeout(() => {
        event.target.value = "";
      }, 100);
    }
  };

  const extractEmails = (formattedRecipients) => {
    // Use a regular expression to extract emails from the formatted string
    return formattedRecipients.map((recipient) => {
      const match = recipient.match(
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      );
      return match ? match[0] : recipient; // Return the email if found, else fallback to the string
    });
  };

  const handleSelectChange = (value, type) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);

    const uniqueEmails = (emails) => [
      ...new Set(emails.map((email) => email.trim().toLowerCase())),
    ];

    switch (type) {
      case "to":
        setToRecipients(uniqueEmails(emailsOnly)); // Deduplicate before updating state
        break;
      case "cc":
        setCcRecipients(uniqueEmails(emailsOnly));
        break;
      case "bcc":
        setBccRecipients(uniqueEmails(emailsOnly));
        break;
      default:
        break;
    }
  };

  const handleHistoryRecipientChange = (value) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);
    const uniqueEmails = (emails) => [
      ...new Set(emails.map((email) => email.trim().toLowerCase())),
    ];
    setRecipientsForHistory(uniqueEmails(emailsOnly));
  };

  const handleHistoryCCChange = (value) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);
    const uniqueEmails = (emails) => [
      ...new Set(emails.map((email) => email.trim().toLowerCase())),
    ];
    setCCForHistory(uniqueEmails(emailsOnly));
  };

  const handleHistoryBCCChange = (value) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);
    const uniqueEmails = (emails) => [
      ...new Set(emails.map((email) => email.trim().toLowerCase())),
    ];
    setBCCForHistory(uniqueEmails(emailsOnly));
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (!searchTerm) return;

    if (searchTerm === "") {
      setFetchedEmails([]);
    }

    const formData = new FormData();
    formData.append("search", searchTerm);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/suggestion_emails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.data?.status === "success") {
        setFetchedEmails(response?.data?.data?.filter(Boolean));
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const mergeEmails = () => {
    const senderContact = email?.data?.contact;
    const senderEmail = email?.data?.sender_email;
    const senderName = senderContact
      ? `${senderContact.first_name || ""} ${
          senderContact.last_name || ""
        }`.trim()
      : email?.data?.sender_name || "";

    const senderCompanyName = senderContact?.company_name || "";

    const formattedSender = senderName
      ? `${senderName} (${senderEmail})${
          senderCompanyName ? ` (${senderCompanyName})` : ""
        }`
      : `${senderEmail}${senderCompanyName ? ` (${senderCompanyName})` : ""}`;

    const formattedFetchedEmails = fetchedEmails.map((contact) => {
      const { first_name, last_name, email, company_name } = contact;

      const namePart =
        first_name && last_name ? `${first_name} ${last_name}` : "";

      return namePart
        ? `${namePart} (${email})${company_name ? ` (${company_name})` : ""}`
        : `${email}${company_name ? ` (${company_name})` : ""}`;
    });

    const mergedEmails = [
      ...new Set([formattedSender, ...formattedFetchedEmails]),
    ].filter(Boolean);

    return mergedEmails;
  };

  const mergeEmailsForHistory = () => {
    const formattedFetchedEmails = fetchedEmails.map((contact) => {
      const { first_name, last_name, email, company_name } = contact;

      const namePart =
        first_name && last_name ? `${first_name} ${last_name}` : "";

      return namePart
        ? `${namePart} (${email})${company_name ? ` (${company_name})` : ""}`
        : `${email}${company_name ? ` (${company_name})` : ""}`;
    });

    const mergedEmails = [...new Set([...formattedFetchedEmails])].filter(
      Boolean
    );

    return mergedEmails;
  };

  const handleSendEmails = async () => {
    setSendingEmail(true);

    // Combine message and email threads
    const combinedMessage = (() => {
      const formattedThreads = [];

      // Iterate over each thread date
      Object.entries(chatThread?.data?.threads || {}).forEach(
        ([date, messages]) => {
          // Sort messages within the same date by their created_at timestamp
          const sortedMessages = messages.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );

          // Format each message
          sortedMessages.forEach((thread) => {
            const label = thread.type === "client" ? "You" : "Starthub";
            formattedThreads.push(`
                      <div style="margin: 8px 0; padding: 10px; border: 1px solid #eee; border-radius: 5px; background-color: #f9f9f9;">
                          <span style="font-weight: bold; color: #333; margin-right: 5px;">${label}:</span>
                          <span style="color: #555;">${thread.message}</span>
                      </div>
                  `);
          });
        }
      );

      return `
          <div>
              <div style="font-size: 16px; font-weight: bold; margin-bottom: 10px;">
                  ${message}
              </div>
              <hr style="margin: 20px 0; border: 1px solid #ddd;" />
              <div>
                  ${formattedThreads.join("")}
              </div>
          </div>
      `;
    })();

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message_body", combinedMessage);

    recipientsForHistory.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );

    ccForHistory.forEach((cc, index) => formData.append(`cc[${index}]`, cc));

    bccForHistory.forEach((bcc, index) =>
      formData.append(`bcc[${index}]`, bcc)
    );

    //// Array.from(selectedFiles).forEach((file, index) => {
    //   formData.append(`attachments[${index}]`, file, file.name);
    // });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/compose`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        setRecipientsForHistory([]);
        setSubject("");
        setValue("");
        setSendChatToEmail(false);
        setSelectedTab("Email");
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <MessageBodyContainer>
      {sendChatToEmail === true && selectedTab === "Transcript" && (
        <>
          <div className="main-div">
            <div className="left-div">
              <span className="name">To</span>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Enter or choose a recipients"
                value={recipientsForHistory}
                onChange={(value) => handleHistoryRecipientChange(value)}
                onSearch={handleSearch}
                tokenSeparators={[",", ";"]}
                notFoundContent={
                  recipientsForHistory?.length > 0 ? (
                    <span>Press enter to add this email</span>
                  ) : (
                    <span>Search contact emails</span>
                  )
                }
                onInputKeyDown={(e) => {
                  if (e.key !== " ") {
                    handleKeyPress(e, "history-email");
                  }
                }}
                searchValue={searchValue}
              >
                {mergeEmailsForHistory(fetchedEmails).map((recipient) => (
                  <Option key={recipient} value={recipient}>
                    {recipient}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="right-div">
              <span
                className="name"
                onClick={() => setShowEmailCc(!showEmailCc)}
              >
                Cc
              </span>
              <span
                className="name"
                onClick={() => setShowEmailBcc(!showEmailBcc)}
              >
                Bcc
              </span>
            </div>
          </div>

          {/* Email Cc Recipients Field */}
          {app?.allOpenConversationDetails?.Model == "LiveChat" && (
            <>
              {showEmailCc && (
                <div className="main-div">
                  <div className="left-div">
                    <span className="name">Cc</span>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Enter or choose a recipients"
                      value={ccForHistory}
                      onChange={(value) => handleHistoryCCChange(value)}
                      onSearch={handleSearch}
                      tokenSeparators={[",", ";"]}
                      notFoundContent={
                        ccForHistory?.length > 0 ? (
                          <span>Press enter to add this email</span>
                        ) : (
                          <span>Search contact emails</span>
                        )
                      }
                      onInputKeyDown={(e) => {
                        if (e.key !== " ") {
                          handleKeyPress(e, "history-cc-email");
                        }
                      }}
                      searchValue={searchValue}
                    >
                      {mergeEmailsForHistory(fetchedEmails).map((recipient) => (
                        <Option key={recipient} value={recipient}>
                          {recipient}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </>
          )}

          {/* Email Bcc Recipients Field */}
          {app?.allOpenConversationDetails?.Model == "LiveChat" && (
            <>
              {showEmailBcc && (
                <div className="main-div">
                  <div className="left-div">
                    <span className="name">Bcc</span>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Enter or choose a recipients"
                      value={bccForHistory}
                      onChange={(value) => handleHistoryBCCChange(value)}
                      onSearch={handleSearch}
                      tokenSeparators={[",", ";"]}
                      notFoundContent={
                        bccForHistory?.length > 0 ? (
                          <span>Press enter to add this email</span>
                        ) : (
                          <span>Search contact emails</span>
                        )
                      }
                      onInputKeyDown={(e) => {
                        if (e.key !== " ") {
                          handleKeyPress(e, "history-bcc-email");
                        }
                      }}
                      searchValue={searchValue}
                    >
                      {mergeEmailsForHistory(fetchedEmails).map((recipient) => (
                        <Option key={recipient} value={recipient}>
                          {recipient}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="main-div">
            <div className="left-div">
              <span className="name">Subject</span>
              <Input
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
                placeholder="Enter subject here..."
                value={subject}
                type="text"
                onChange={(e) => setSubject(e.target.value)}
              ></Input>
            </div>
          </div>
        </>
      )}

      {/* To Recipients Field */}
      {selectedFolder !== "Chat" &&
      app?.allOpenConversationDetails?.Model != "LiveChat" &&
      selectedTab === "Email" ? (
        <div className="main-div">
          <div className="left-div">
            <span className="back-icon">
              <IoReturnUpForward />
            </span>
            <Select
              mode="multiple"
              suffixIcon={
                <MdOutlineKeyboardArrowDown
                  size={18}
                  width="1rem"
                  height="1rem"
                ></MdOutlineKeyboardArrowDown>
              }
              allowClear
              style={{ width: "100%" }}
              placeholder="Enter or choose recipients"
              value={toRecipients}
              onChange={(value) => handleSelectChange(value, "to")}
              onSearch={handleSearch}
              tokenSeparators={[",", ";"]}
              notFoundContent={<span>Press enter to add this email</span>}
              onInputKeyDown={(e) => {
                if (e.key !== " ") {
                  handleKeyPress(e, "to");
                }
              }}
              searchValue={searchValue}
            >
              {mergeEmails(fetchedEmails).map((recipient) => (
                <Option key={recipient} value={recipient}>
                  {recipient}
                </Option>
              ))}
            </Select>
          </div>
          <div className="right-div">
            <span className="name" onClick={() => setShowCc(!showCc)}>
              Cc
            </span>
            <span className="name" onClick={() => setShowBcc(!showBcc)}>
              Bcc
            </span>
          </div>
        </div>
      ) : null}

      {/* Cc Recipients Field */}
      {selectedFolder != "Chat" && selectedTab === "Email" && (
        <>
          {showCc && (
            <div className="main-div">
              <div className="left-div">
                <span className="name">Cc</span>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Enter or choose Cc recipients"
                  value={ccRecipients}
                  onChange={(value) => handleSelectChange(value, "cc")}
                  onSearch={handleSearch}
                  tokenSeparators={[",", ";"]}
                  notFoundContent={<span>Press enter to add this email</span>}
                  onInputKeyDown={(e) => {
                    if (e.key !== " ") {
                      handleKeyPress(e, "cc");
                    }
                  }}
                  searchValue={searchValue}
                >
                  {mergeEmails(fetchedEmails).map((recipient) => (
                    <Option key={recipient} value={recipient}>
                      {recipient}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </>
      )}

      {/* Bcc Recipients Field */}
      {selectedFolder != "Chat" && selectedTab === "Email" && (
        <>
          {showBcc && (
            <div className="main-div">
              <div className="left-div">
                <span className="name">Bcc</span>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Enter or choose Bcc recipients"
                  value={bccRecipients}
                  onChange={(value) => handleSelectChange(value, "bcc")}
                  onSearch={handleSearch}
                  tokenSeparators={[",", ";"]}
                  notFoundContent={<span>Press enter to add this email</span>}
                  onInputKeyDown={(e) => {
                    if (e.key !== " ") {
                      handleKeyPress(e, "bcc");
                    }
                  }}
                  searchValue={searchValue}
                >
                  {mergeEmails(fetchedEmails).map((recipient) => (
                    <Option key={recipient} value={recipient}>
                      {recipient}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </>
      )}
      {/* Quill Editor */}

      <div className="text-area-wrapper">
        <ReactQuill
          name="message"
          className="quill-editor"
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          placeholder="Type your message here..."
          modules={{
            toolbar: {
              container: "#quill-toolbar",
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true,
            },
          }}
          // onKeyDown={(event) => {
          //   if (event.key === "Enter") {
          //     if (event.shiftKey) {
          //       return;
          //     }
          //     if (value.trim()) {
          //       event.preventDefault();
          //       handleSend();
          //     } else {
          //       event.preventDefault();
          //     }
          //   }
          // }}
        />
      </div>

      {/* Attachment List */}
      <AttachmentList>
        {forwardFiles?.map((file, index) => (
          <AttachmentItem key={index}>
            <TruncateText text={file.filename} />
            <span className="file-size">
              ({(file.size_in_bytes / 1024).toFixed(1)}kb)
            </span>
            <RemoveIcon onClick={() => handleForwardedFile(index)}>
              <IoClose />
            </RemoveIcon>
          </AttachmentItem>
        ))}
      </AttachmentList>
      <AttachmentList>
        {selectedFiles.map((file, index) => (
          <AttachmentItem key={index}>
            <TruncateText text={file.name} />
            <span className="file-size">
              ({(file.size / 1024).toFixed(1)}kb)
            </span>
            <RemoveIcon onClick={() => handleRemoveFile(index)}>
              <IoClose />
            </RemoveIcon>
          </AttachmentItem>
        ))}
      </AttachmentList>

      {/* Buttons */}
      <ButtonsWrapper>
        <div id="quill-toolbar" className="custom-toolbar">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-link"></button>
        </div>

        <Button onClick={handleFileClick}>
          <FiPaperclip />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
        </Button>

        <button
          onClick={sendChatToEmail ? handleSendEmails : handleSend}
          className="send-button"
          disabled={sendingMail || value === ""}
        >
          {sendingMail || sendingEmail
            ? "Sending"
            : isForwardMail
            ? "Forward Mail"
            : "Send"}
        </button>
      </ButtonsWrapper>
    </MessageBodyContainer>
  );
};

export default MessageBody;
