import React, { useState, useEffect, useRef } from "react";
import { Form } from "antd";
import {
  Banner,
  ComponentsContainer,
  ProductPlansEnableAndContactContainer,
  PlanFormsContainer,
  MainContainer,
  FixedContainer,
} from "./styels";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import useGetData from "../../hooks/useGetData";
import { InstanceWithAuth } from "../../App";
import Spinner from "../../common/Spinner";
import PlansSidebar from "../../components/phoneNumberDetails/plansSidebar/PlansSidebar";
import IncludedAddOns from "../../components/phoneNumberDetails/includedAddOns/IncludedAddOns";
import UserVisibility from "../../components/phoneNumberDetails/userVisibility/UserVisibility";
import DetailForm from "../../components/phoneNumberDetails/detailForm/DetailForm";
import DefaultEndDate from "../../components/phoneNumberDetails/defaultEndDate/DefaultEndDate";
import VoiceMailSettings from "../../components/phoneNumberDetails/voiceMailSettings/VoiceMailSettings";
import { useGlobalAlertMessage } from "../../hooks/useAlertMessage";

const PhoneNumberDetails = () => {
  const { showMessage } = useGlobalAlertMessage();
  const [active, setActive] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const observer = useRef(null);
  const { app, auth } = useSelector((state) => state);
  const [selectedServices, setSelectedServices] = useState([]);
  const [oneTimeProduct, setOneTimeProduct] = useState(false);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [doesNotEnd, setDoesNotEnd] = useState(false);
  const [canChooseEndDate, setCanChooseEndDate] = useState(false);
  const [fixedTerm, setFixedTerm] = useState(false);
  const [trialDays, setTrialDays] = useState();
  const [trialYears, setTrialYears] = useState();
  const [freePlan, setFreePlan] = useState("paid");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [voiceMailTitle, setVoiceMailTitle] = useState(null);

  const { data: locationsData } = useGetData("locations");
  const handleAddAllLocations = () => {
    if (locationsData && locationsData.data) {
      const allLocationIds = locationsData.data.map((item) => item.id);
      form.setFieldsValue({
        location_ids: locationsData.data ? allLocationIds : [],
      });
      setDropdownVisible(false);
    }
  };

  const selectdServicesIds = [];
  selectedServices?.map((service) => {
    selectdServicesIds.push(service?.id);
  });
  const selectdAddOnsIds = [];
  selectedAddOns.map((service) => {
    selectdAddOnsIds.push(service.id);
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();

  function convertToBinary(value) {
    if (typeof value === "boolean") {
      return value ? 1 : 0;
    } else {
      return 0;
    }
  }

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);
          const formData = new FormData();
          console.log(values);
          formData.append("name", values?.name);
          formData.append("description", values?.description);
          formData.append(
            "is_visible_to_new_user",
            convertToBinary(values?.is_visible_to_new_user)
          );
          formData.append(
            "is_visible_to_existing_user",
            convertToBinary(values?.is_visible_to_existing_user)
          );
          if (doesNotEnd) {
            formData.append("does_endable", convertToBinary(doesNotEnd));
          }

          if (canChooseEndDate) {
            formData.append("end_date", date);
          }

          if (fixedTerm) {
            formData.append("fixed_term_days", values?.fixed_term_days);
          }

          formData.append("is_one_time", convertToBinary(values?.is_one_time));
          if (values?.is_one_time) {
            formData.append("one_time_price", values?.one_time_price);
            formData.append("one_time_setup_fee", values?.one_time_setup_fee);
          }
          formData.append("billing_type", freePlan);
          formData.append("is_monthly", convertToBinary(values?.is_monthly));
          if (values?.is_monthly) {
            formData.append("monthly_price", values?.monthly_price);
            formData.append("monthly_setup_fee", values?.monthly_setup_fee);
          }

          formData.append("is_yearly", convertToBinary(values?.is_yearly));
          if (values?.is_yearly) {
            formData.append("yearly_price", values?.yearly_price);
            formData.append("yearly_setup_fee", values?.yearly_setup_fee);
          }

          formData.append(
            "include_trial",
            convertToBinary(values?.include_trial)
          );

          if (values?.include_trial) {
            formData.append("trial_duration_for_monthly", trialDays);
            formData.append("trial_duration_for_yearly", trialYears);
          }
          formData.append("status", 1);

          selectdServicesIds.length > 0 &&
            selectdServicesIds.map((id, index) =>
              formData.append(`service_ids[${index}]`, id)
            );

          selectdAddOnsIds.length > 0 &&
            selectdAddOnsIds.map((id, index) =>
              formData.append(`addon_ids[${index}]`, id)
            );

          values?.location_ids &&
            values?.location_ids.map((id, index) =>
              formData.append(`location_ids[${index}]`, id)
            );

          formData.append(
            "service_settings",
            '{\n    "1": {\n        "Product Amount": 1000\n    },\n    "2": {\n        "test": "name",\n        "desc": "testng"\n    }\n}\n'
          );
          const response = await InstanceWithAuth.post("plans", formData, {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          if (response?.status === 201) {
            console.log(response?.status);

            const formData = new FormData();

            values?.location_ids &&
              values?.location_ids.map((id, index) =>
                formData.append(`location_ids[${index}]`, id)
              );
            formData.append("plan_id", response?.data?.plan?.id);

            const locationResponse = await InstanceWithAuth.post(
              "plans/assign_locations",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${auth?.userObj?.api_token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (locationResponse?.status === 200) {
              showMessage({
                type: "success",
                content: response?.data?.message,
              });
              navigate("/plan-and-products");
            } else {
              showMessage({
                type: "error",
              });
            }
          }
        } catch (error) {
          showMessage({
            type: "error",
            content: error?.response?.data?.message,
          });
        } finally {
          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const scrollToSection = (id) => {
    setActive(id);
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        if (visibleSection) {
          setActiveSection(visibleSection.id);
        }
      },
      {
        rootMargin: "-40% 0px",
        scrollMargin: "-40% 0px",
        delay: 100,
      }
    );

    const sections = document.querySelectorAll(".scroll-section");

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleSubmit = (value) => {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "42px",
      }}
    >
      <CommonHaeder
        firstBcItem={"Plans & Products"}
        firstBcIcon="plains and products"
        firtBcUrl="/plan-and-products"
        secondBcItem={"Create Plan"}
        secondBcIcon="plains and products"
      ></CommonHaeder>
      <MainContainer collapsed={app?.sidebarCollapsed}>
        <Banner>
          <div className="label">Create Plan</div>
          <div className="btns">
            <div className="btns">
              <button
                className="cancel-btn"
                onClick={() => navigate("/plan-and-products")}
              >
                <span className="btn-text">Cancel</span>
              </button>
              <button className="save-btn" onClick={handleExternalSubmit}>
                <span className="btn-text">
                  {loading ? <Spinner></Spinner> : "Save"}
                </span>
              </button>
            </div>
          </div>
        </Banner>

        <PlanFormsContainer>
          <PlansSidebar
            scrollToSection={scrollToSection}
            active={active}
            activeSection={activeSection}
          />
          <FixedContainer className="fixed">
            <Form
              className="main-form"
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
            >
              <ProductPlansEnableAndContactContainer>
                <ComponentsContainer>
                  <DetailForm
                    id={1}
                    handleAddAllLocations={handleAddAllLocations}
                    dropdownVisible={dropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                  />

                  <UserVisibility id={2} />

                  <DefaultEndDate
                    id={3}
                    oneTimeProduct={oneTimeProduct}
                    setOneTimeProduct={setOneTimeProduct}
                    doesNotEnd={doesNotEnd}
                    setDoesNotEnd={setDoesNotEnd}
                    canChooseEndDate={canChooseEndDate}
                    setCanChooseEndDate={setCanChooseEndDate}
                    fixedTerm={fixedTerm}
                    setFixedTerm={setFixedTerm}
                    setDate={setDate}
                  />

                  <IncludedAddOns
                    id={4}
                    selectedAddOns={selectedAddOns}
                    setSelectedAddOns={setSelectedAddOns}
                    selectedServices={selectedServices}
                  />

                  <VoiceMailSettings
                    id={5}
                    selectedAddOns={selectedAddOns}
                    setSelectedAddOns={setSelectedAddOns}
                    selectedServices={selectedServices}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    voiceMailTitle={voiceMailTitle}
                    setVoiceMailTitle={setVoiceMailTitle}
                  />
                </ComponentsContainer>
              </ProductPlansEnableAndContactContainer>
            </Form>
          </FixedContainer>
        </PlanFormsContainer>
      </MainContainer>
    </div>
  );
};

export default PhoneNumberDetails;
