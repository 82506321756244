import React, { useEffect, useRef, useState } from "react";
import "./styles";
import { MainConatiner, Container, ProductContainer } from "./styles";
import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import Spinner from "../../../../common/Spinner";
import useGetData from "../../../../hooks/useGetData";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

const MailboxSettings = ({}) => {
  const [dataSending, setDataSending] = useState(false);
  const { auth } = useSelector((state) => state);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { showMessage } = useGlobalAlertMessage();
  const { data: productSettingsData, error } =
    useGetData(`services/1/settings`);

  useEffect(() => {
    if (productSettingsData) {
      form.setFieldsValue({
        mail_storage: productSettingsData?.data?.mail_storage,
        scan_allowed: productSettingsData?.data?.scan_allowed,
        min_days_to_start_charging_packages:
          productSettingsData?.data?.min_days_to_start_charging_packages,
        addition_scan_per_mail_item:
          productSettingsData?.data?.addition_scan_per_mail_item,
        Forwarding: productSettingsData?.data?.Forwarding,
        expediated_forwarding: productSettingsData?.data?.expediated_forwarding,
        package_forwarding: productSettingsData?.data?.package_forwarding,
        international_forwarding:
          productSettingsData?.data?.international_forwarding,
        international_package_forwarding:
          productSettingsData?.data?.international_package_forwarding,
        pickup: productSettingsData?.data?.pickup,
        deposit: productSettingsData?.data?.deposit,
        additional_days_for_large_envelope_storage:
          productSettingsData?.data?.additional_days_for_large_envelope_storage,
        additional_days_for_package_storage:
          productSettingsData?.data?.additional_days_for_package_storage,
        par_day_additional_charges_for_package_storage:
          productSettingsData?.data
            ?.par_day_additional_charges_for_package_storage,
        par_day_additional_charges_for_large_envelope_storage:
          productSettingsData?.data
            ?.par_day_additional_charges_for_large_envelope_storage,
      });
    }
  }, [productSettingsData]);

  const onFinish = async (values) => {
    const formData = new URLSearchParams();
    formData.append("mail_storage", values?.mail_storage);
    formData.append("scan_allowed", values?.scan_allowed);
    formData.append(
      "min_days_to_start_charging_packages",
      values?.min_days_to_start_charging_packages
    );
    formData.append(
      "addition_scan_per_mail_item",
      values?.addition_scan_per_mail_item
    );
    formData.append("Forwarding", values?.Forwarding);
    formData.append("expediated_forwarding", values?.expediated_forwarding);
    formData.append("package_forwarding", values?.package_forwarding);
    formData.append(
      "international_forwarding",
      values?.international_forwarding
    );
    formData.append(
      "international_package_forwarding",
      values?.international_package_forwarding
    );
    formData.append("pickup", values?.pickup);
    formData.append("deposit", values?.deposit);

    formData.append(
      "additional_days_for_large_envelope_storage",
      values?.additional_days_for_large_envelope_storage
    );

    formData.append(
      "additional_days_for_package_storage",
      values?.additional_days_for_package_storage
    );

    formData.append(
      "par_day_additional_charges_for_package_storage",
      values?.par_day_additional_charges_for_package_storage
    );

    formData.append(
      "par_day_additional_charges_for_large_envelope_storage",
      values?.par_day_additional_charges_for_large_envelope_storage
    );

    try {
      setDataSending(true);
      const response = await InstanceWithAuth.put(
        "services/1/settings",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setDataSending(false);
        showMessage({
          type: "success",
          content: response?.data?.message,
        });      }
    } catch (error) {
      setDataSending(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <MainConatiner>
      <Container>
        <div className="title-main">
          <p className="title">Mailbox Settings</p>
        </div>
        <ProductContainer>
          <Form
            form={form}
            requiredMark={false}
            className="form"
            onFinish={onFinish}
            ref={formRef}
          >
            <Form.Item
              label="Default Mailbox Storage"
              name="mail_storage"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter the mailbox storage!",
                },
              ]}
            >
              <Input className="username-input" placeholder="Mailbox Storage" />
            </Form.Item>

            <Form.Item
              label="Default Scans Allowed"
              name="scan_allowed"
              className="input-label"
              rules={[
                { required: true, message: "Please enter the scans allowed!" },
              ]}
            >
              <Input className="username-input" placeholder="Scans Allowed" />
            </Form.Item>

            <Form.Item
              label="Min Days to start Charging Packages"
              name="min_days_to_start_charging_packages"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter the minimum days to start charging!",
                },
              ]}
            >
              <Input
                className="username-input"
                placeholder="Min Days to start"
              />
            </Form.Item>

            <Form.Item
              label="Additional Scan per Mail Item"
              name="addition_scan_per_mail_item"
              className="input-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the additional scan per mail item cost!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Forwarding"
              name="Forwarding"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter the forwarding cost!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Expediated Forwarding"
              name="expediated_forwarding"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter the expediated forwarding cost!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Package Forwarding"
              name="package_forwarding"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter the package forwarding cost!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="International Forwarding"
              name="international_forwarding"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter the international forwarding cost!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="International Package Forwarding"
              name="international_package_forwarding"
              className="input-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the international package forwarding cost!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Pickup"
              name="pickup"
              className="input-label"
              rules={[
                { required: true, message: "Please enter the pickup cost!" },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Deposit"
              name="deposit"
              className="input-label"
              rules={[
                { required: true, message: "Please enter the deposit amount!" },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Additional days for Large Envelope Storage"
              name="additional_days_for_large_envelope_storage"
              className="input-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the additional days for large envelope storage!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Additional days for Packages Storage"
              name="additional_days_for_package_storage"
              className="input-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the additional days for packages storage!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Par day additional charges for package storage"
              name="par_day_additional_charges_for_package_storage"
              className="input-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter par day additional charges for package storage!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Par day additional charges for large envelope storage"
              name="par_day_additional_charges_for_large_envelope_storage"
              className="input-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter par day additional charges for large envelope storage!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>
          </Form>
        </ProductContainer>
        <div className="button-div">
          <button type="button" onClick={handleSubmit}>
            {dataSending ? <Spinner /> : "Save"}
          </button>
        </div>
      </Container>
    </MainConatiner>
  );
};

export default MailboxSettings;
