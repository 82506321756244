import React from "react";
import { Container } from "./styles";
import { Form, Input, Switch } from "antd";
import GetLogo from "../../getlogo/getlogo";

const MailboxSettings = ({}) => {
  const [form] = Form.useForm();

  return (
    <Container>
      <span className="title">Mailbox Settings </span>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Default Parmeters</span>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Default Mailbox Storage Allowed"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" suffix="GB" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Default Scans Allowed per Month"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Forwarding & Charges</span>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Forwarding Fees per Request"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" suffix="$" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Number of Forwards Included Before Charges Apply"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Scans Included Per Month Before Charges Apply"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Check Deposits Included Before Charges Apply"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="left">
            <span className="product-name">Storage Fees</span>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="No. of Days Before Storage Fees Apply"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" suffix="$" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Storage Fee per day for Packages"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" suffix="$" />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main-2" style={{ marginTop: "10px" }}>
          <div className="form">
            <Form form={form}>
              <Form.Item
                label="Storage Fee per day for large Envelopes"
                name="easy_post_key"
                className="form-items"
              >
                <Input className="input" placeholder="45" suffix="$" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MailboxSettings;
