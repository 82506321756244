import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 548px;
  gap: 20px;

  .title {
    font-family: Outfit;
    font-size: 18px;
    font-weight: 500;
    line-height: 22.68px;
    letter-spacing: -0.035em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--Dark-Gray-100, #2B3746);
  }

  .setting-card {
    width: 548px;
    height: 205px;
    padding: 16px 24px 24px 24px;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #D0DEE1;
    opacity: 1;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .product-logo {
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }

        .product-name {
          font-family: Outfit;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: var(--Dark-Gray-100, #2B3746);
        }
      }

      .right {
        padding: 5px 24px;
        gap: 6px;
        border-radius: 4px;
        border: 2px solid #BFDDE5;
        font-family: Outfit;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.64px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        cursor: pointer;
      }
    }

    .form-main {
      display: flex;
      flex-direction: column;
      height: 66px;
      max-width: 500px;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        height: 134px;
        max-width: 500px;
        width: 100%;
        .form-items {
          margin-bottom: 0;
          height: 64px;
          max-width: 500px;
        }

        .input {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          width: 500px;
          height: 41px;
          border-radius: 4px;
          box-shadow: none;
          border-color: unset;
          border: 1px solid #d0dee1;
          background: #fff;
        }

        .input::placeholder {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .input:focus::placeholder {
          color: transparent;
        }
        .input:focus {
          box-shadow: none;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input {
          border-color: unset;
          border: 1px solid #d0dee1;
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }
        .ant-form-item .ant-form-item-label > label::after {
          content: "";
        }
        .ant-form-item-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
          align-self: stretch;

          label {
            height: unset;
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            align-self: stretch;
          }
        }
        .location {
          align-items: center;
          min-width: 500px;
          height: 41px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          height: auto;
          min-height: 42px;
          padding-top: 4px;
          padding-right: 15px !important;

          .ant-select-selector {
            border: none;
            outline: none;
            box-shadow: none;
          }
          .location::placeholder {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .location:focus::placeholder {
            color: transparent;
          }
          .ant-select-selection-item {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }
  
    
  }

  
`;

const RadioContainer = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(208, 222, 225, 0.4);
  max-height: 50px;
  margin: 10px 0px;

  .label {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-top: 10px;
  }

  .ant-switch {
    min-width: 26px !important;
    /* height: 22px; */
    /* height: 16px;   */
  }
  .ant-switch.ant-switch-checked {
    background-color: #4cbbcb !important;
  }`

export { Container, RadioContainer };
