import React, { useState } from "react";
import { Collapse, Skeleton } from "antd";
import { CompanyCards, Container, ContentContainer } from "./styles";
import "./styles";
import { useNavigate, useParams } from "react-router-dom";
import useGetData from "../../../../../hooks/useGetData";
import DataNotFound from "../../../../../common/DataNotFound";
import FormatDate from "../../../../../common/FormatDate";
import StatusLabelButton from "../../../../statusLabelButton/StatusLabelButton";

const { Panel } = Collapse;

const Companies = ({
  userID,
  setClickedCompany,
  showDrawer,
  OpenCompanyInSideView,
  OpenCompanyInSideViewForTicketCenter,
  handleShowUserDetails,
}) => {
  const { data, loading, error } = useGetData(`companies?contact_id=${userID}`);
  const [collopasedKey, setCollopasedKey] = useState(false);
  const { ticketID } = useParams();
  const navigate = useNavigate();

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel
          header={
            data?.data?.length !== 0
              ? `Companies    (${data?.data?.length})`
              : `Companies`
          }
          key="1"
          className="account-panel"
        >
          <ContentContainer>
            {loading ? (
              <Skeleton active></Skeleton>
            ) : data?.data?.length == 0 ? (
              <DataNotFound label={"No Company Found"} />
            ) : (
              data?.data?.map((company) => (
                <CompanyCards>
                  <div className="company-labels">
                    <p className="company-name">{company?.name}</p>
                    <span className="office">{company?.plan_name}</span>
                  </div>
                  <div className="time-details">
                    <p className="date">{FormatDate(company?.created_at)}</p>
                    <div className="btns">
                      <StatusLabelButton Data={company}></StatusLabelButton>
                      <button
                        className="view-btn"
                        onClick={(e) => {
                          if (OpenCompanyInSideView) {
                            showDrawer("company", company?.id);
                          } else if (OpenCompanyInSideViewForTicketCenter) {
                            handleShowUserDetails(
                              e,
                              company?.id,
                              "company",
                              true
                            );
                          } else {
                            ticketID
                              ? setClickedCompany(company)
                              : navigate(`/companies/${company?.id}`);
                          }
                        }}
                      >
                        <span className="btn-text">View</span>
                      </button>
                    </div>
                  </div>
                </CompanyCards>
              ))
            )}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  );
};

export default Companies;
