import React, { useEffect, useState } from "react";
import { Collapse, Skeleton } from "antd";
import { Container, ContentContainer, B } from "./styles";

import "./styles";
import CraeteTicket from "./craeteTicket/CraeteTicket";
import useGetData from "../../../../../hooks/useGetData";

const { Panel } = Collapse;

const Ticket = ({ selectedEmailId, userID }) => {
  const [collopasedKey, setCollopasedKey] = useState(false);
  const [isPipelineModelOpen, setIsPipelineModelOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [stages, setStages] = useState([]);
  const { data: pipelines, error: pipelinesError } = useGetData(
    `support/tickets/pipelines?status=1`
  );
  const {
    data: tickets,
    error: ticketsError,
    loading,
  } = useGetData(
    `support/tickets?order_by=created_at&order=DESC&contact_id=${userID}`
  );

  useEffect(() => {
    if (pipelines && pipelines?.data?.length > 0) {
      setSelectedPipeline(pipelines?.data[0]);
      setStages(pipelines?.data[0].stages || []);
    }
  }, [pipelines]);

  const handlePipelineChange = (pipelineId) => {
    const pipeline = pipelines?.data?.find((p) => p.id === pipelineId);
    setSelectedPipeline(pipeline);
    setStages(pipeline?.stages || []);
  };

  const handleOk = () => {
    setIsPipelineModelOpen(false);
  };
  const handleCancel = () => {
    setIsPipelineModelOpen(false);
  };

  const showModal = () => {
    setIsPipelineModelOpen(true);
  };

  const TruncateText = ({ text }) => {
    const maxLength = 50;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  https: return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Tickets" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="tickets">
                {tickets?.data?.map((ticket, index) => (
                  <div key={index} className="ticket">
                    <span className="name">{ticket?.name}</span>
                    <span className="des">
                      {" "}
                      <TruncateText text={ticket?.description}></TruncateText>
                    </span>
                    <div className="make-row">
                      <div className="left">
                        <span className="priority-label">Priority: </span>
                        <span className="priority">{ticket?.priority}</span>
                      </div>

                      <div className="right">
                        {ticket?.is_closed ? (
                          <div className="in-active">
                            <span className="text">Open</span>
                          </div>
                        ) : (
                          <div className="active">
                            <span className="text">Open</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <span>Create a ticket from this conversation</span>
            <button onClick={showModal}>Create Ticket</button>
          </ContentContainer>
        </Panel>
      </Collapse>

      <CraeteTicket
        isPipelineModelOpen={isPipelineModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        pipelines={pipelines?.data}
        selectedPipeline={selectedPipeline}
        stages={stages}
        onPipelineChange={handlePipelineChange}
        selectedEmailId={selectedEmailId}
      />
    </Container>
  );
};

export default Ticket;
