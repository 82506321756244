import React from "react";
import { Container, SidebarItem, Dropdown, DropdownItem } from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const menuItems = [
  "General Settings",
  "Product Settings",
  "Ticket Management",
  "Invoicing",
];

const dropdownItems = [
  "Mailbox Settings",
  "Live Answering",
  "Meeting Room Access",
];

const SettingsSidebar = ({ activeTab, setActiveTab }) => {
  const isProductActive = dropdownItems.includes(activeTab);
  const [isProductOpen, setIsProductOpen] = React.useState(isProductActive);

  return (
    <Container>
      {menuItems.map((item) => (
        <React.Fragment key={item}>
          <SidebarItem
            onClick={() => {
              if (item === "Product Settings") {
                setIsProductOpen(!isProductOpen);
              } else {
                setActiveTab(item);
              }
            }}
            isActive={
              activeTab === item ||
              (item === "Product Settings" && isProductActive)
            }
          >
            {item}{" "}
            {item === "Product Settings" &&
              (isProductOpen ? (
                <span className="icon">
                  <IoIosArrowUp color="#343B4C" size={16} />
                </span>
              ) : (
                <span className="icon">
                  <IoIosArrowDown color="#343B4C" size={16} />{" "}
                </span>
              ))}
          </SidebarItem>

          {/* Render dropdown items right after "Product Settings" */}
          {item === "Product Settings" && isProductOpen && (
            <Dropdown>
              {dropdownItems.map((subItem) => (
                <DropdownItem
                  key={subItem}
                  onClick={() => setActiveTab(subItem)}
                  isActive={activeTab === subItem}
                >
                  {subItem}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default SettingsSidebar;
